import React from "react";

interface PartnerLogo {
    id: number;
    imageUrl: string;
    altText: string;
}

const clientLogos: PartnerLogo[] = [
    { id: 1, imageUrl: "/partners/500px-698px-Samsung_Logo.svg.png", altText: "Samsung" },
    { id: 2, imageUrl: "/partners/800px-Cisco_logo.svg.png", altText: "Cisco" },
    { id: 3, imageUrl: "/partners/1000px-ASUS_Logo.svg.png", altText: "ASUS" },
    { id: 4, imageUrl: "/partners/Acer-Logo_2011.png", altText: "Acer" },
    { id: 5, imageUrl: "/partners/BrightSign_logo.jpg", altText: "BrightSign" },
    { id: 6, imageUrl: "/partners/com&c.jpg", altText: "Com&c" },
    { id: 7, imageUrl: "/partners/EPSON.png", altText: "EPSON" },
    { id: 8, imageUrl: "/partners/GL-LOGO_PNG-Format.png", altText: "GL" },
    { id: 9, imageUrl: "/partners/logo-zyxel.png", altText: "Zyxel" },
    { id: 10, imageUrl: "/partners/Logo_hp.png", altText: "HP" },
    { id: 11, imageUrl: "/partners/official-apple-logo-png.png", altText: "Apple" },
    { id: 12, imageUrl: "/partners/officina-delle-idee.jpg", altText: "Officina delle Idee" },
    { id: 13, imageUrl: "/partners/optoma-logo.png", altText: "Optoma" }
];

const PartnerGrid: React.FC = () => {
    return (
        <div className="flex flex-col relative space-y-10 pt-20">
            <div className="flex items-center w-full">
                <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-5 gap-6 text-white w-full  ">
                    <div className="col-span-full flex flex-col justify-center items-center pb-16">
                        <h1 className="text-4xl md:text-6xl uppercase">OUR TECHNICAL PARTNERS</h1>
                        <p className="py-4">We collaborate with leading hardware providers to deliver the best-in-class digital signage solutions.</p>
                    </div>
                    {clientLogos.map((logo) => (
                        <div
                            key={logo.id}
                            className="flex flex-col items-center my-4"
                            data-aos="fade-zoom-in"
                            data-aos-offset="200"
                            data-aos-delay={`${300 + logo.id * 100}`}
                            data-aos-easing="ease-in-sine"
                            data-aos-duration="600"
                            data-aos-once="true"
                        >
                            <div className="flex items-center justify-center w-full h-full">
                                <img
                                    className="object-cover h-5 sm:h-7 md:h-10 w-auto hover:scale-110 transform transition-transform duration-200 ease-in-out "
                                    src={logo.imageUrl}
                                    alt={logo.altText}
                                   // style={{ filter: "brightness(0) invert(1)" }} // Makes the logo white
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PartnerGrid;
