import ImageText from "../common/ImageText";
import Counter from "../common/Counter";
import ProductDescription from "../common/ProductDescription";
import Collapsible from "../common/Collapsible";
import TechSpecs from "../common/TechSpecs";
import ProductSlider from "../common/ProductSlider";
import EmailPopup from '../common/EmailPopup';
import "../global.css";
import { Link } from "react-router-dom";
import React, {useEffect, useState} from "react";
import OverlayForm from "../components/OverlayForm";
import {Canvas} from "@react-three/fiber";
import GLTFModel from "../common/configuratore/GLTFModel";
import {Environment, OrbitControls} from "@react-three/drei";
import {Bloom, EffectComposer, TiltShift} from "@react-three/postprocessing";
import PageHeader from "../components/PageHeader";
import BlackButtons from "../common/BlackButtons";
import ImageTextHome from "../common/ImageText_Home";
import CareersSlider from "../common/CareersSlider";
import HeritageSlider from "../common/HeritageSlider";
import ProductWebForm from "../components/PipedriveForm";
import ExhibitSlider from "../common/ExhibitSlider";
import SignageSlider from "../common/ExhibitSlider";
import PartnerGrid from "../common/PartnerGrid";
import SectionsHeader from "../components/SectionsHeader";

export default function DigitalSignage() {

  useEffect(() => {
    if (window.location.hash) {
      let newPath = window.location.pathname + window.location.search;
      if (newPath.endsWith('/')) {
        console.log("termina con /", newPath)
        newPath = newPath.slice(0, -1);
        console.log("newPath", newPath)
      }
      window.history.pushState("", document.title, newPath);
    }
  }, []);

  const [isPopupOpen, setIsPopupOpen] = useState(false);


  const handleRequestClick = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div>

      <div className="relative flex flex-col h-full  mt-20">
        {/* TITLE Section */}
        <div className="h-screen flex flex-col   z-40 opacity-0 animate-fadeIn delay-1  ">
          <div className="absolute inset-0  top-0 left-0 w-auto h-screen z-0 opacity-70" >
            {/*<img src="/projects/MARTINI LEDWALL/CASA_MARTINI_0.jpg" alt="sara" className="object-cover w-full h-full" />*/}
            <img src="/projects/MARTINI LEDWALL/CASA_MARTINI_1.jpg" alt="sara" className="object-cover w-full h-full pointer-events-none" />
          </div>

        <div className="flex flex-col">
          <SectionsHeader title={"DIGITAL SIGNAGE"} subtitle={"Innovative Display Solutions for Dynamic Communication"}/>

          <div className="absolute inset-0 h-screen w-auto">
            <div className="relative flex justify-center items-center h-screen w-auto pt-20 z-40"> <BlackButtons to1="/services/digital-signage/#infos"  description1="what we do" to2="/services/digital-signage/#gallery" description2="CASE HISTORY" /></div>

          </div>
        </div>
      </div>

        <div className="mx-12 mb-24 h-full flex flex-col bg-opacity-50 z-40 pt-10">
          <div>
            <div className="grid  md:grid-rows-2 gap-4 sm:grid-cols-1 gap-y-20 ">
              <div
                  className="value-item"
                  data-aos="zoom-out-up"
                  data-aos-offset="100"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
              >
                <ImageTextHome
                    text="WHAT WE DO"
                    imageSrc="/LogoRibes_In_Out - Copy.gif"
                description="We design, implement, and manage digital signage solutions tailored to various environments, including public spaces, museums, retail, and corporate settings. Our systems help deliver dynamic content that engages audiences, conveys important information, and elevates the overall experience. From interactive touchscreens to large-scale video walls, our digital signage adapts to the needs of the space, providing high-quality visual communication." textAlign="left"
                    />
              </div>
              <div
                  className="value-item"
                  data-aos="zoom-out-up"
                  data-aos-offset="100"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
              >
                <ImageTextHome
                    text="HOW WE DO IT"
                    imageSrc="/LogoRibes_In_Out - Copy.gif"
                    description="We begin by assessing the unique needs of your space and audience, selecting the best hardware and software solutions to meet your goals. Our team handles the entire installation process, from planning and wiring to setting up displays and configuring content management systems (CMS). We offer scalable solutions, whether you need a single screen or a network of synchronized displays. Additionally, we ensure that our systems are easy to manage, offering ongoing maintenance and updates to keep your content fresh and your systems running smoothly." textAlign="right"
                    reverse={true}
                />
              </div>

              <div className="value-item"
                   data-aos="zoom-out-up"
                   data-aos-offset="100"
                   data-aos-easing="ease-in-sine"
                   data-aos-duration="600">
                <ImageTextHome
                    text="WHY IT'S IMPORTANT"
                    imageSrc="/LogoRibes_In_Out - Copy.gif"
                    description="Digital signage enhances communication, making it more interactive, engaging, and immediate. Whether providing real-time updates or advertising content, it plays a crucial role in connecting with the audience in public spaces. Importantly, in public venues, digital signage devices must meet specific certification standards for safety, durability, and image quality. Devices are certified to meet standards such as IP ratings for dust and water resistance, UL certifications for electrical safety, and compliance with industry norms for commercial-grade displays. This ensures that your signage performs reliably and safely in high-traffic environments, standing up to the rigors of daily use." textAlign="left"
                    reverse={false}
                />
              </div>
            </div>
          </div>
        </div>



        <div id='main-features' className="h-20"></div>

        {/* OPEN POSITIONS Section */}
        <div className="px-10">
          <div
            className="flex items-center justify-center"
            data-aos="fade-zoom-in"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            {/*immagine product*/}
            <img 
              src="/projects/TABORROBAK/img.png"
              className="w-auto md:w-5/12  h-auto "
                 alt="sara"
            />
          </div>
          <div>
            {/*cta download*/}
            <div
              className="flex flex-col  sm:flex-row justify-center items-center gap-2 md:gap-24  font-bold  "
              data-aos="fade-zoom-in"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
            >

              <div className="flex justify-center  pb-20"> <BlackButtons to1="/services/service-1"  description1="OUR CLIENTS" to2="/services/service-1" description2="CASE HISTORY" onClick={()=> handleRequestClick()} /></div>


            </div>
          </div>

          <div className="py-20 " id="infos"></div>
          <h1 data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="0"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              className=" mb-12 text-5xl leading-120 tracking-tighter text-center uppercase  text-white " >
            WHAT WE CAN DO{" "}

          </h1>

          <div data-aos="fade-zoom-in"
               data-aos-offset="100px"
               data-aos-easing="ease-in-sine"
               data-aos-duration="600"
               data-aos-once="true">
            <ProductDescription
                title="Interactive Touchscreens"
                description="We install responsive touchscreens that allow users to interact with content, explore information, and navigate menus intuitively. These touchscreens can be customized with interactive maps, virtual tours, multimedia presentations, and more, providing visitors with engaging and informative experiences. Our touchscreens are designed to be user-friendly and accessible, making them ideal for museums, galleries, retail spaces, and other public venues."
            /></div>

          <div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title="top quality projectors"
                description=" We offer high-quality projectors that deliver bright, clear images in any environment. Our projectors are ideal for large-scale displays, presentations, and immersive experiences, providing vibrant visuals that captivate audiences. Whether it's a conference room, auditorium, or public space, our projectors offer versatile and reliable solutions for dynamic communication."
            />
          </div>

          <div data-aos="fade-zoom-in"
               data-aos-offset="100px"
               data-aos-delay="500"
               data-aos-easing="ease-in-sine"
               data-aos-duration="600"
               data-aos-once="true">
            <ProductDescription
                title="Video Walls"
                description="arge-scale video wall installations create immersive, high-impact displays that can showcase videos, advertisements, or real-time updates in vibrant clarity. Our video walls are customizable to fit any space, providing a dynamic visual experience that captivates audiences."
            />
          </div>
          <div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title="Standalone Digital Displays"
                description="Our high-definition digital displays are perfect for showing static or rotating content, including digital posters, menus, or promotional materials. These standalone displays can be mounted on walls, stands, or kiosks, providing versatile options for showcasing information in any space."
            />
          </div>
          <div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title="Kiosks and Wayfinding Solutions"
                description="We offer interactive kiosks that provide directions, maps, and important information, guiding users seamlessly through any environment. Our wayfinding solutions are designed to be user-friendly and accessible, helping visitors navigate complex spaces with ease. Whether it's a museum, shopping center, or corporate office, our kiosks enhance the visitor experience and provide valuable assistance."
            />
          </div>
          <div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title="Outdoor Digital Signage"
                description="Designed to withstand harsh weather conditions, our outdoor digital displays feature durable enclosures and anti-glare technology to ensure visibility in any light. These displays can be used for advertising, wayfinding, or public information, providing a dynamic communication solution for outdoor spaces. Whether it's a city square, park, or outdoor event, our digital signage solutions deliver high-quality content that engages audiences and enhances the environment."
            />
          </div> <div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title="LED Displays"
                description="Ultra-bright LED screens are perfect for high-traffic areas, delivering clear and engaging visuals that attract attention. Our LED displays can be customized to any size or shape, making them ideal for advertising, branding, or information displays. Whether it's an indoor or outdoor setting, our LED screens provide a versatile and eye-catching solution for dynamic communication."
            />
          </div><div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title="Digital Menu Boards"
                description="Ideal for restaurants and cafes, digital menu boards can be easily updated to reflect new items, pricing, or promotional offers in real-time. Our menu boards are designed to be visually appealing and easy to read, enhancing the customer experience and driving sales. With dynamic content and eye-catching visuals, our digital menu boards help restaurants stand out and engage customers."
            />
          </div><div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title="Remote Content Management Systems (CMS)"
                description="We provide advanced CMS platforms that allow users to control and update content across multiple displays from a single remote interface."
            />
          </div><div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title="Augmented Reality (AR) Displays"
                description="Create an interactive experience by integrating AR technology into your digital signage, allowing users to engage with the display using their smartphones or tablets. AR displays can provide additional information, virtual tours, or interactive games, enhancing the overall experience and making your content more engaging and memorable."
            />
          </div><div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title="Virtual Reality (VR) Headsets"
                description=" For a fully immersive experience, we offer VR headsets that allow users to explore virtual environments, interact with 3D content, and engage with interactive displays. VR headsets can be used for educational purposes, entertainment, or training, providing a unique and engaging way to experience digital content."
            />
          </div><div
            data-aos="fade-zoom-in"
            data-aos-offset="100px"
            data-aos-delay="1000"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
            data-aos-once="true">
          <ProductDescription
              title="Transparent OLED Displays"
              description="These cutting-edge displays feature transparent screens that can showcase content while allowing viewers to see through the display. Transparent OLED displays are ideal for creating eye-catching visual effects, interactive installations, or product showcases that blend digital content with the physical environment."
          />
        </div><div
            data-aos="fade-zoom-in"
            data-aos-offset="100px"
            data-aos-delay="1000"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
            data-aos-once="true">
          <ProductDescription
              title="CUSTOM SOLUTIONS"
              description="We offer custom digital signage solutions tailored to your specific needs, whether you require a unique display configuration, interactive features, or specialized content. Our team works closely with you to design, develop, and implement a solution that meets your goals and exceeds your expectations. From concept to installation, we provide end-to-end support to ensure that your digital signage solution is a perfect fit for your space and audience."
          />
        </div>


        </div>

        {/* PRODUCT GALLERY Section */}
        <div className="h-full flex flex-col bg-black bg-opacity-50 z-40 mb-40 " id="gallery">
          {/*  */}
          <h1
              data-aos="fade-zoom-in"
              data-aos-offset="300px"
              data-aos-delay="0"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              className=" mt-20 mb-40  text-5xl leading-120 tracking-tighter text-center uppercase text-white ">
            PRODUCT GALLERY{" "}
          </h1>

          <div data-aos="fade-zoom-in"
               data-aos-offset="300px"
               data-aos-delay="300"
               data-aos-easing="ease-in-sine"
               data-aos-duration="600">
            <SignageSlider />
          </div>



        </div>

        <div className="h-full flex flex-col  z-50 mb-40 pt-20" id="partners" >
          <div>

            <PartnerGrid/>
          </div>
        </div>

        {/*ANIMATED BLOCKS*/}

        <div className="  h-full flex flex-col bg-black bg-opacity-50 z-0 my-20">

          <div className=" grid grid-cols-1 md:grid-cols-3 gap-4  sm:mt-0 text-white">
            <Counter type="power" start={1} end={26} unit="y" />
            <Counter type="letter" start="" end="TECH" />
            <Counter type="watt" start={100} end={1000} unit="" />
          </div>
        </div>

        {/*AVAILABLE VERSIONS Section*/}
        <div className="h-full flex flex-col bg-black bg-opacity-50 z-40 mb-40 ">
          {/* Your content here */}
          <h1 data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="0"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              className=" my-12 text-5xl leading-120 tracking-tighter text-center uppercase f text-white ">
            DO YOU HAVE AN IDEA?
          </h1>

          <div>
            <div className="grid  md:grid-cols-3  gap-4 px-4 sm:grid-cols-1">
              <div className="">
                {/*bg-green-500*/}
                <div className="value-item p-4  transform transition-transform duration-200 hover:scale-110 hover:text-white hover:bg-none hover:border-4 hover:border-white hover:shadow-[0_0_15px_rgba(255,165,0,0.7)]"
                     data-aos="fade-zoom-in"
                     data-aos-offset="100px"
                     data-aos-delay="500"
                     data-aos-easing="ease-in-sine"
                     data-aos-duration="600">
                  <ImageText
                    imageSrc="/icons/stylus_note (1).svg"
                    title="we can design that"
                    description={"We create custom designs, from exhibition layouts to digital interfaces,\n ensuring visually engaging and functional results.Each wing controlled independently"}
                    textAlign="center"
                  />
                </div>
              </div>

              <div className=" ">
                {/*bg-green-500*/}
                <div className="value-item p-4  transform transition-transform duration-200 hover:scale-110 hover:text-white hover:bg-none hover:border-4 hover:border-white hover:shadow-[0_0_15px_rgba(255,165,0,0.7)]"
                     data-aos="fade-zoom-in"
                     data-aos-offset="100px"
                     data-aos-delay="800"
                     data-aos-easing="ease-in-sine"
                     data-aos-duration="600">
                  <ImageText
                    imageSrc="/icons/desktop_windows (1).svg"
                    title="we can install that"
                    description={"We provide comprehensive hardware installation, integrating all\n components seamlessly for optimal performance and synchronization."}
                    textAlign="center"
                  />
                </div>
              </div>

              <div className="value-item  p-4  transform transition-transform duration-200 hover:scale-110 hover:text-white hover:bg-none hover:border-4 hover:border-white hover:shadow-[0_0_15px_rgba(255,165,0,0.7)]"
                   data-aos="fade-zoom-in"
                   data-aos-offset="100px"
                   data-aos-delay="1100"
                   data-aos-easing="ease-in-sine"
                   data-aos-duration="600" >
                <ImageText
                  imageSrc="/icons/volunteer_activism.svg"
                  title="we can assist you overtime"
                  description={"We build advanced, custom software solutions and we take care of maintenance overtime."}
                  textAlign="center"
                />
              </div>
            </div>
          </div>
        </div>
                  <div id="tech-specs" className="h-10"></div>



        {/* VIDEO Section */}
        {/*<div className="opacity-0 animate-fadeIn delay-0 h-full flex flex-col bg-black bg-opacity-50 z-40">


        <div>
          <div>

            <video
                className="inset-0 w-screen h-full object-cover"
                loop={true}
                autoPlay={false}
                controls
            >
              <source src="/SARA_2.0_DIDASCALIE.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>*/}

        {/*FORM SECTION*/}
        <div className="max-w-md mx-auto mt-0 px-6 "  data-aos="fade-zoom-in"
             data-aos-offset="300px"
             data-aos-delay="0"
             data-aos-easing="ease-in-sine"
             data-aos-duration="600">
          <h1
              id='positions'
              className="opacity-0 animate-fadeIn delay-2 my-12 text-5xl leading-120 tracking-tighter text-center uppercase font-medium text-white ">
            LET'S TALK ABOUT IT!
          </h1>
          <ProductWebForm
              formId="95a0e1ff-e505-4b30-9253-a3f16bc466a6"
              portalId="145047450"
              region="eu1"
          />
        </div>



        <div className="mt-40  z-40 bg-black">
          <Link to="/contact">
            <div className="flex items-center justify-center ">
              <button
                  className=" w-auto h-10 text-center text-white  py-2 px-4 transform transition-transform duration-200 hover:scale-110"
                  style={{
                    background: "linear-gradient(90deg, #000000 0%, #eb6000 100%)",
                  }}
              >
                CONTACT US
              </button>
            </div>
          </Link>
        </div>

      </div>
      {/* {isPopupOpen && fileType && (
          <EmailPopup fileType={fileType} onClose={handleClosePopup} />
      )}*/}
      {isPopupOpen && (
          <OverlayForm
              formUrl="https://webforms.pipedrive.com/f/6UR9Re8nR5VKrsxbJHNTBDLcsXv6xjIStMZYDU4iiOvEJdEo7cZwg4M1uAm9NAG7WX"
              onClose={handleClosePopup}
          />
      )}
    </div>
  );
}
