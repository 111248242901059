import "../global.css";
import React, { useEffect } from "react";
import {Link} from "react-router-dom";
import PipedriveForm from "../components/PipedriveForm";
import ProductWebForm from "../components/PipedriveForm";
import PageHeader from "../components/PageHeader";
import BlackButtons from "../common/BlackButtons";
import ClientGrid from "../common/ClientGrid";
import PartnerGrid from "../common/PartnerGrid";
import {Canvas} from "@react-three/fiber";
import GLTFModel from "../common/configuratore/GLTFModel";
import {Environment, OrbitControls} from "@react-three/drei";
import {Bloom, EffectComposer, TiltShift} from "@react-three/postprocessing";
import SectionsHeader from "../components/SectionsHeader";

export default function Clients() {
    useEffect(() => {
        if (window.location.hash) {
          let newPath = window.location.pathname + window.location.search;
          if (newPath.endsWith('/')) {
            console.log("termina con /", newPath)
            newPath = newPath.slice(0, -1);
            console.log("newPath", newPath)
          }
          window.history.pushState("", document.title, newPath);
        }
      }, []);


    return (
        <div>
          
            <div className="relative flex flex-col h-full mt-20 ">
                {/* BECOME A REVOLVER Section */}

                <div className="h-screen flex flex-col   z-40  opacity-0 animate-fadeIn delay-1 ">
                    {/* Your content here */}
                    <div className="absolute inset-0  top-0 left-0 w-auto h-screen z-0 opacity-80" >
                        <img src="/projects/MUSEO EGIZIO GALLERIA DELLA SCRITTURA/img_4.png" alt="sara" className="object-cover w-full h-full " />
                    </div>
                    <div className="flex flex-col">
                        <SectionsHeader title={"WE ARE TRUSTED BY"} subtitle={"BUILDING LASTING RELATIONSHIPS BASED ON TRUST AND DEDICATION"}/>

                    <div className="absolute inset-0 h-screen w-auto">
                        <div className="relative flex justify-center items-center h-screen w-auto pt-20 z-40"> <BlackButtons to1="/clients/#clients"  description1="OUR CLIENTS" to2="/clients/#partners" description2="TECHNICAL PARTNERS" /></div>

                    </div>

                    </div>


                </div>
            </div>

            {/* CONTACTS Section */}
            <div className="h-full flex flex-col bg-black  bg-opacity-50 z-50 mb-40 pt-20" id="clients">

                <div>

                    <ClientGrid/>
                </div>
            </div>

            {/* advantages of working with us*/}

            <div className="h-full flex flex-col bg-black  bg-opacity-50 z-50 mb-40 pt-20" id="partners" >
                <div>

                    <PartnerGrid/>
                </div>
            </div>



            <div  id='form-contacts' className="pb-20" ></div>

            {/*FORM SECTION*/}
             <div className="max-w-md mx-auto mt-10 p-6 "  >

                 <h1 className="text-4xl md:text-6xl uppercase z-40 text-white opacity-0 animate-fadeIn delay-2 ">do you need help? let's talk about it!</h1>
                 <div data-aos="fade-zoom-in"
                      data-aos-offset="300px"
                      data-aos-delay="0"
                      data-aos-easing="ease-in-sine"
                      data-aos-duration="600">
                     <ProductWebForm
                         formId="95a0e1ff-e505-4b30-9253-a3f16bc466a6"
                         portalId="145047450"
                         region="eu1"
                     />
                 </div>

            </div>





        </div>
    );
}
