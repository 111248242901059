import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { logPageView } from '../types/ga'; // Adjust the import path accordingly

interface GAListenerProps {
    children: React.ReactNode;
}

const GAListener: React.FC<GAListenerProps> = ({ children }) => {
    const location = useLocation();

    useEffect(() => {
        logPageView(location.pathname, document.title);
    }, [location]);

    return <>{children}</>;
};

export default GAListener;
