import { useLocation, useNavigate } from "react-router-dom";
import HeadingAndSubtitle from "../common/news/HeadingAndSubtitile";
import MainStructureNews from "../common/news/MainStructureNews";
import SmallNews from "../common/news/SmallNews";
import SquareNews from "../common/news/SquareNews";
import { useEffect, useState } from "react";
import { NewsItem } from "../common/news/types";

export default function NewsDetail() {
  const location = useLocation();
  const navigate = useNavigate();
  const [seeMore, setSeeMore] = useState(false); // Aggiunto per gestire il pulsante "Vedi di più" [vedi sotto

  const newsItem = location.state?.NewItem; // Usa l'operatore opzionale per evitare errori se state è undefined
  const filteredNews = location.state?.filteredNews as NewsItem[]; // Usa l'operatore opzionale per evitare errori se state è undefined
  return (
    <div>
      <div className="h-full pt-20 relative">
        <img
          src="/news/News0.png"
          className="object-cover object-center w-full h-96 z-10 opacity-60 md:opacity-100"
          alt=""
        />
        <div className="absolute top-1/2 md:top-full left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20 w-3/4 pt-20 md:pb-32">
          <h1 className="text-center text-white mb-4 font-bold text-montserrat bold text-2xl">
            {newsItem?.titleNews}
          </h1>
        </div>
      </div>
      {/* HEADING AND SUBTITLE*/}
      <div className="grid grid-cols-3 gap-4 text-white p-8">
        <div className="col-span-3 md:col-span-2">
          <HeadingAndSubtitle
            heading={newsItem?.titleNews}
            subtitle={newsItem?.subtitle}
          />
        </div>
        <div className="col-span-3 md:col-span-1 justify-self-center ">
          <SquareNews
            publicationDate={newsItem?.publication_date}
            category={newsItem?.category}
            readingTime={newsItem?.reading_time}
            authorName={newsItem?.author_name}
          />
        </div>
      </div>
      {/*QUOTES*/}
      <div className="p-8">
        <div className="grid grid-cols-5 gap-4 text-white ">
          <div className="col-span-1">
            <img src="/news/format_quote.svg" alt="1" />
          </div>
          <div className="col-span-3">
            <p className="text-white text-2xl">{newsItem?.quote1}</p>
            <p className="text-gray-400 text-md">{newsItem?.quote1_names}</p>
          </div>
        </div>
      </div>

      {/*MAIN STRUCTURE*/}
      <div className=" p-8">
        <MainStructureNews
          heading_1={newsItem?.heading_1}
          subtitle_1={newsItem?.subtitle_1}
          quote_1={newsItem?.quote_1}
          quote_author_1={newsItem?.quote_author_1}
          image_1={
            newsItem?.image_1
              ? "/IMAGES/" + newsItem?.news_id + "/image_1.png"
              : ""
          }
          heading_2={newsItem?.heading_2}
          subtitle_2={newsItem?.subtitle_2}
          quote_2={newsItem?.quote_2}
          quote_author_2={newsItem?.quote_author_2}
          image_2={
            newsItem?.image_2
              ? "/IMAGES/" + newsItem?.news_id + "/image_2.png"
              : ""
          }
          heading_3={newsItem?.heading_3}
          subtitle_3={newsItem?.subtitle_3}
          quote_3={newsItem?.quote_3}
          quote_author_3={newsItem?.quote_author_3}
          image_3={
            newsItem?.image_3
              ? "/IMAGES/" + newsItem?.news_id + "/image_3.png"
              : ""
          }
          heading_4={newsItem?.heading_4}
          subtitle_4={newsItem?.subtitle_4}
          quote_4={newsItem?.quote_4}
          quote_author_4={newsItem?.quote_author_4}
          image_4={
            newsItem?.image_4
              ? "/IMAGES/" + newsItem?.news_id + "/image_4.png"
              : ""
          }
          heading_5={newsItem?.heading_5}
          subtitle_5={newsItem?.subtitle_5}
          quote_5={newsItem?.quote_5}
          quote_author_5={newsItem?.quote_author_5}
          image_5={
            newsItem?.image_5
              ? "/IMAGES/" + newsItem?.news_id + "/image_5.png"
              : ""
          }
        />
      </div>

      <div className="space-y-3 px-8">
        <HeadingAndSubtitle
          heading={newsItem?.header_paragraph_1}
          subtitle={newsItem?.description_paragraph_1}
        />

        <HeadingAndSubtitle
          heading={newsItem?.header_paragraph_2}
          subtitle={newsItem?.description_paragraph_2}
        />

        <HeadingAndSubtitle
          heading={newsItem?.header_paragraph_3}
          subtitle={newsItem?.description_paragraph_3}
        />
      </div>
      {filteredNews.length > 1 && (
        <div className="h-full pt-20">
          <div className="bg-gradient-to-r from-gradient-start to-gradient-end h-0.5"></div>

          <h1 className="text-4xl md:text-6xl font-bold text-white text-center pt-32 ">
            Related News
          </h1>

          <div className="grid grid-cols-1 md:grid-cols-4 gap-4 p-8">
            {(seeMore ? filteredNews : filteredNews.slice(0, 5)).map(
              (filterednewsItem: NewsItem, index: number) =>
                filterednewsItem.news_id !== newsItem.news_id ? (
                  <div
                    onClick={() => {
                      if (filterednewsItem.link) {
                        // Se newsItem ha un link, reindirizza l'utente a quel link
                        window.open(filterednewsItem.link, "_blank");
                      } else {
                        navigate(
                          `/news/${filterednewsItem.category}/${filterednewsItem.news_id}`,
                          {
                            state: {
                              NewItem: filterednewsItem,
                              filteredNews: filteredNews,
                            },
                          }
                        );
                      }
                    }}
                    key={index} // Using index as a key is not recommended for dynamic lists
                    className="cursor-pointer"
                  >
                    <SmallNews
                      title={filterednewsItem.titleNews}
                      description={filterednewsItem.subtitle}
                      imageSrc={
                        "/IMAGES/" + filterednewsItem?.news_id + "/small.png"
                      }
                      category={filterednewsItem.category}
                      readingTime={filterednewsItem.reading_time}
                    />
                  </div>
                ) : null
            )}
          </div>
          <div className="flex justify-center">
            {/* Mostra il pulsante "SEE MORE" solo se ci sono più di 4 notizie */}
            {filteredNews.length > 4 && !seeMore && (
              <button
                onClick={() => {
                  setSeeMore(!seeMore); // Aggiornamento dello stato di "Vedi di più"
                }}
                className=" with-border hover:bg-gradient-to-r from-gradient-start to-gradient-end text-white py-2 px-4 rounded "
              >
                SEE MORE
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
