import React from 'react';

interface ImageTextCareersProps {
    text?: string;
    imageSrc: string;
    title?: string;
    description?: string;
    textAlign?: 'left' | 'center' | 'right';
}

class ImageTextCareer extends React.Component<ImageTextCareersProps> {
    render() {
        const { text, imageSrc, title, description, textAlign = 'center' } = this.props;

        return (
            <div className="relative flex flex-col items-center space-y-0 h-full">
                <img src={imageSrc} alt={text} className="w-[9/12] h-auto mb-6 " />
                <h1 className="text-center font-bold  text-white uppercase  text-1xl"> {title} </h1>
                <p style={{textAlign: textAlign}} className="relative bottom-0 text-center text-white uppercase  text-3xl font-montserrat font-medium">{text}</p>
                {/*                {description && <p style={{textAlign: textAlign}} className=" text-left uppercase text-white w-3/4 h-auto text-base font-montserrat font-normal" dangerouslySetInnerHTML={{ __html: description.replace(/\n/g, '<br />') }}>{description}</p>}
*/}
                {description && <p style={{textAlign: textAlign}} className=" text-left uppercase text-white w-3/4 h-auto text-base font-montserrat font-normal pt-4" dangerouslySetInnerHTML={{ __html: description.replace(/\n/g, '<br />') }} />}
            </div>
        );
    }
}

export default ImageTextCareer;