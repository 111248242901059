import React from "react";

interface RoundedNewsProps {
  imageSrc?: string;
  title?: string;
  description?: string;
}
//<img src={"/news/News0.png"} alt={title} className="h-1/2 w-full rounded-full object-cover" />

const RoundedNews: React.FC<RoundedNewsProps> = ({
  imageSrc,
  title,
  description,
}) => {
  return (
    <div className="h-full ">
      <div className="flex space-x-3 h-full">
        <div className="w-2/3 h-full ">
          <img
            src={imageSrc ? imageSrc : "/news/News0.png"}
            alt={title}
            className=" h-32 w-full rounded-full object-cover object-center"
          />
        </div>

        <div className="w-1/3 h-full space-y-1">
          <h1 className="text-left text-white text-md line-clamp-2">
            {title}{" "}
          </h1>
          <p className="relative bottom-0 text-left text-white text-xs overflow-ellipsis line-clamp-4">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default RoundedNews;
