import Slider from 'react-slick';
import "./ImageSlider.css";

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
const settings = {
    dots: false,
    arrows: true, 
    infinite: true,
    speed: 500,
    slidesToShow:2,
    slidesToScroll: 1,
    swipeToSlide: true,
    centerMode: true,
};

const AutomotiveSlider = () => (
    <Slider {...settings} className="flex ">

        <img src="/projects/EXTRA/car.png" alt="" className=" h-[500px] object-contain " />
        <img src="/projects/EXTRA/img.png" alt="" className=" h-[500px] object-contain " />

        <img src="/projects/EXTRA/maserati.png" alt="" className="h-[500px] object-contain " />
        <img src="/projects/MARTINI/Martini-007.jpg" alt="" className=" h-[500px] object-contain " />
        <img src="/projects/EXTRA/LAYOUT.png" alt="" className="h-[500px] object-contain " />
        <img src="/projects/MARTINI/Martini-017.jpg" alt="" className=" h-[500px] object-contain " />
        <img src="/projects/EXTRA/Rectangle%20(10).png" alt="" className=" h-[500px] object-contain " />
        <img src="/projects/EXTRA/bmw-projection-mapping-7-10.jpg" alt="" className=" h-[500px] object-contain " />
        <img src="/projects/EXTRA/img_1.png" alt="" className=" h-[500px] object-contain " />







    </Slider>
);





export default AutomotiveSlider;