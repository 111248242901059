import ImageText from "../common/ImageText";
import Counter from "../common/Counter";
import ProductDescription from "../common/ProductDescription";
import Collapsible from "../common/Collapsible";
import TechSpecs from "../common/TechSpecs";
import ProductSlider from "../common/ProductSlider";
import EmailPopup from '../common/EmailPopup';
import "../global.css";
import { Link } from "react-router-dom";
import React, {useEffect, useState} from "react";
import OverlayForm from "../components/OverlayForm";
import {Canvas} from "@react-three/fiber";
import GLTFModel from "../common/configuratore/GLTFModel";
import {Environment, OrbitControls} from "@react-three/drei";
import {Bloom, EffectComposer, TiltShift} from "@react-three/postprocessing";
import PageHeader from "../components/PageHeader";
import BlackButtons from "../common/BlackButtons";
import ImageTextHome from "../common/ImageText_Home";
import CareersSlider from "../common/CareersSlider";
import HeritageSlider from "../common/HeritageSlider";
import ProductWebForm from "../components/PipedriveForm";
import SectionsHeader from "../components/SectionsHeader";
import AutomotiveSlider from "../common/AutomotiveSlider";

export default function Automotive() {

  useEffect(() => {
    if (window.location.hash) {
      let newPath = window.location.pathname + window.location.search;
      if (newPath.endsWith('/')) {
        console.log("termina con /", newPath)
        newPath = newPath.slice(0, -1);
        console.log("newPath", newPath)
      }
      window.history.pushState("", document.title, newPath);
    }
  }, []);






  return (
    <div>

      <div className="relative flex flex-col h-full  mt-20">
        {/* TITLE Section */}
        <div className="h-screen flex flex-col   z-40 opacity-0 animate-fadeIn delay-1 ">
          <div className="absolute inset-0  top-0 left-0 w-auto h-screen z-40 opacity-70" >



            <video src="/EVO37%20Teaser.mp4" className=" w-full h-full object-cover" autoPlay={true} loop={true} muted playsInline />

          </div>

        <div className="flex flex-col">
          <SectionsHeader title={"AUTOMOTIVE"} subtitle={"Innovative Digital Solutions for Showrooms and Trade Shows" +
              "\n"}/>

          <div className="absolute inset-0 h-screen w-auto">
            <div className="relative flex justify-center items-center h-screen w-auto pt-20 z-40"> <BlackButtons to1="/services/service-1/#infos"  description1="what we do" to2="/services/service-1/#gallery" description2="CASE HISTORY" /></div>

          </div>
        </div>
        </div>

        <div className="mx-12 mb-24 h-full flex flex-col bg-opacity-50 z-40 pt-10">
          <div>
            <div className="grid  md:grid-rows-2 gap-4 sm:grid-cols-1 gap-y-20 ">
              <div
                  className="value-item"
                  data-aos="zoom-out-up"
                  data-aos-offset="100"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
              >
                <ImageTextHome
                    text="WHAT WE DO"
                    imageSrc="/LogoRibes_In_Out - Copy.gif"
                    description="We provide cutting-edge interactive and multimedia solutions tailored to the automotive industry. From enhancing the customer experience in showrooms to creating engaging, tech-driven displays for trade shows, our services help automotive brands stand out. We combine advanced technology with creative design to deliver solutions that not only attract attention but also provide immersive, informative, and interactive experiences for potential customers."
                    textAlign="left"
                />
              </div>
              <div
                  className="value-item"
                  data-aos="zoom-out-up"
                  data-aos-offset="100"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
              >
                <ImageTextHome
                    text="HOW WE DO IT"
                    imageSrc="/LogoRibes_In_Out - Copy.gif"
                    description="Our process starts with understanding the specific goals and needs of your automotive brand, whether for a showroom or a trade show. We develop custom interactive displays, digital signage, and virtual experiences that allow customers to engage with your vehicles in a meaningful way. Our technology solutions are designed to streamline the sales process, provide real-time information, and create a lasting impression. From concept to execution, we manage every aspect, including installation, system integration, and ongoing support to ensure flawless performance."
                    textAlign="right"
                    reverse={true}
                />
              </div>
            </div>
          </div>
        </div>



        <div id='main-features' className="h-20"></div>

        {/* OPEN POSITIONS Section */}
        <div className="px-10">
          <div
            className="flex items-center justify-center"
            data-aos="fade-zoom-in"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            {/*immagine product*/}
            <img 
              src="/projects/EXTRA/img_1.png"
              className="w-auto md:w-5/12  h-auto "
                 alt="car"
            />
          </div>
          <div>
            {/*cta download*/}
            <div
              className="flex flex-col  sm:flex-row justify-center items-center gap-2 md:gap-24  font-bold  "
              data-aos="fade-zoom-in"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
            >

              <div className="flex justify-center  pb-20"> <BlackButtons to1="/clients"  description1="OUR CLIENTS" to2="/services/automotive/#gallery" description2="CASE HISTORY" onClick={()=> {}} /></div>


            </div>
          </div>

          <div className="py-20 " id="infos"></div>
          <h1 data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="0"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              className=" mb-12 text-5xl leading-120 tracking-tighter text-center uppercase  text-white " >
            WHAT WE CAN DO{" "}

          </h1>

          <div data-aos="fade-zoom-in"
               data-aos-offset="100px"

               data-aos-easing="ease-in-sine"
               data-aos-duration="600"
               data-aos-once="true">
            <ProductDescription
                title="Interactive Touchscreen Displays"
                description="We install large, high-definition interactive screens in showrooms, allowing customers to explore vehicle configurations, features, and options in detail, creating a personalized experience."          />
          </div>

          <div data-aos="fade-zoom-in"
               data-aos-offset="100px"
               data-aos-delay="500"
               data-aos-easing="ease-in-sine"
               data-aos-duration="600"
               data-aos-once="true">
            <ProductDescription
                title="Digital Signage and Video Walls"
                description="Enhance your showroom or trade show booth with dynamic digital signage and video walls that showcase vehicles, promotional content, and brand stories in a visually stunning manner."
            />
          </div>
          <div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title="Augmented Reality (AR) for Training and Maintenance"
                description="Using AR, we create interactive training modules and maintenance guides that help operators understand complex machinery and processes. This immersive approach improves learning and reduces downtime during maintenance."
            />
          </div>
          <div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title="Augmented Reality (AR) and Virtual Reality (VR) Experiences"
                description="Using AR and VR, we create immersive experiences where customers can virtually explore vehicles, view 360-degree models, and even take virtual test drives. This is perfect for showrooms or trade show environments."
            />
          </div>
          <div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title="Vehicle Configurator Platforms"
                description="Our vehicle configurator tools allow customers to customize their car selections, from color to accessories, providing an engaging and tailored buying experience. These platforms can be integrated into both showroom touchscreens and online."
            />
          </div>
          <div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title="Immersive Multimedia Displays"
                description="We integrate large-scale, immersive displays into production environments, providing dynamic visual feedback on system performance and operational data, enabling interactive decision-making right on the factory floor."
            />
          </div> <div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title="Interactive Showroom Tables"
                description="Interactive tables allow customers to engage with multimedia content, such as vehicle models, specifications, and comparisons, right in the showroom. These tables create an immersive, hands-on experience."
            />
          </div><div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title="Mobile Applications for Showrooms"
                description="We develop custom mobile apps for automotive brands, allowing customers to explore vehicles, schedule test drives, and even receive personalized offers. These apps create seamless interactions between digital and physical showroom experiences."
            />
          </div><div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title="Projection Mapping for Vehicle Launches"
                description="Projection mapping transforms vehicles and showrooms into immersive storytelling platforms, highlighting specific features, designs, and performance in a way that captivates the audience during launches or trade shows."
            />
          </div>
          <div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title="Digital Kiosks for Trade Shows"
                description="Our digital kiosks provide an interactive platform where visitors can access detailed information, watch videos, and explore vehicle features at trade shows, creating a memorable and educational experience."
            />
          </div><div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title="Connected Showroom Solutions"
                description="We offer complete network and system integration solutions that connect various digital displays, vehicle systems, and customer engagement tools, ensuring a unified and efficient showroom experience."
            />
          </div><div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title="Touchless Installations"
                description="We implement touchless technology in showrooms and trade shows, allowing customers to interact with digital displays through gestures, voice commands, or proximity sensors. This technology enhances the experience by providing a hygienic, futuristic interface that aligns with modern consumer expectations."
            />
          </div>


        </div>

        {/* PRODUCT GALLERY Section */}
        <div className="h-full flex flex-col bg-black bg-opacity-50 z-40 mb-40 " id="gallery">
          {/*  */}
          <h1
              data-aos="fade-zoom-in"
              data-aos-offset="300px"
              data-aos-delay="0"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              className=" mt-20 mb-40  text-5xl leading-120 tracking-tighter text-center uppercase text-white ">
            PRODUCT GALLERY{" "}
          </h1>

          <div data-aos="fade-zoom-in"
               data-aos-offset="300px"
               data-aos-delay="300"
               data-aos-easing="ease-in-sine"
               data-aos-duration="600">
            <AutomotiveSlider />
          </div>

        </div>

        {/*ANIMATED BLOCKS*/}

        <div className="  h-full flex flex-col bg-black bg-opacity-50 z-0 my-20">

          <div className=" grid grid-cols-1 md:grid-cols-3 gap-4  sm:mt-0 text-white">
            <Counter type="power" start={1} end={26} unit="y" />
            <Counter type="letter" start="" end="TECH" />
            <Counter type="watt" start={100} end={1000} unit="" />
          </div>
        </div>

        {/*AVAILABLE VERSIONS Section*/}
        <div className="h-full flex flex-col bg-black bg-opacity-50 z-40 mb-40 ">
          {/* Your content here */}
          <h1 data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="0"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              className=" my-12 text-5xl leading-120 tracking-tighter text-center uppercase f text-white ">
            DO YOU HAVE AN IDEA?
          </h1>

          <div>
            <div className="grid  md:grid-cols-3  gap-4 px-4 sm:grid-cols-1">
              <div className="">
                {/*bg-green-500*/}
                <div className="value-item p-4  transform transition-transform duration-300 hover:scale-110 hover:text-white hover:bg-none hover:border-4 hover:border-white hover:shadow-[0_0_15px_rgba(255,165,0,0.7)] ease-in-out"
                     data-aos="fade-zoom-in"
                     data-aos-offset="100px"
                     data-aos-delay="500"
                     data-aos-easing="ease-in-sine"
                     data-aos-duration="600">
                  <ImageText
                    imageSrc="/icons/stylus_note (1).svg"
                    title="we can design that"
                    description={"We create custom designs, from exhibition layouts to digital interfaces,\n ensuring visually engaging and functional results.Each wing controlled independently"}
                    textAlign="center"
                  />
                </div>
              </div>

              <div className=" ">
                {/*bg-green-500*/}
                <div className="value-item p-4  transform transition-transform duration-200 hover:scale-110 hover:text-white hover:bg-none hover:border-4 hover:border-white hover:shadow-[0_0_15px_rgba(255,165,0,0.7)]"
                     data-aos="fade-zoom-in"
                     data-aos-offset="100px"
                     data-aos-delay="800"
                     data-aos-easing="ease-in-sine"
                     data-aos-duration="600">
                  <ImageText
                    imageSrc="/icons/desktop_windows (1).svg"
                    title="we can project hardware"
                    description={"We provide comprehensive hardware installation, integrating all\n components seamlessly for optimal performance and synchronization."}
                    textAlign="center"
                  />
                </div>
              </div>

              <div className="value-item  p-4  transform transition-transform duration-200 hover:scale-110 hover:text-white hover:bg-none hover:border-4 hover:border-white hover:shadow-[0_0_15px_rgba(255,165,0,0.7)]"
                   data-aos="fade-zoom-in"
                   data-aos-offset="100px"
                   data-aos-delay="1100"
                   data-aos-easing="ease-in-sine"
                   data-aos-duration="600" >
                <ImageText
                  imageSrc="/icons/sdk (1).svg"
                  title="we can develop software"
                  description={"We build advanced, custom software solutions that deliver flawless\n interactive experiences tailored to your project needs."}
                  textAlign="center"
                />
              </div>
            </div>
          </div>
        </div>
                  <div id="tech-specs" className="h-10"></div>
        {/*
        <div  className="h-full flex flex-col bg-black bg-opacity-50 z-40 mb-40">

          <h1
              data-aos="fade-zoom-in"
              data-aos-offset="300px"
              data-aos-delay="0"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              className=" my-12 text-5xl leading-120 tracking-tighter text-center uppercase font-bold text-white ">
            TECHNICAL SPECIFICATIONS{" "}
          </h1>

          <div>
            <div className="grid  md:grid-cols-4  gap-4 px-4 sm:grid-cols-1 " >

              <div
                  data-aos="fade-zoom-in"
                  data-aos-offset="300px"
                  data-aos-delay="400"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600">
                <TechSpecs
                    title="MECHANICAL SPECS"
                    description={[
                      { title: "Size: ", description: "97x97x23mm" },
                      { title: "Mass: ", description: "0.3kg" },
                      { title: "Rotation range: ", description: " +/- 180°" },
                      {
                        title: "Nominal rotation speed: ",
                        description: " 10 deg/s",
                      },
                    ]}

                />
              </div>
              <div
                  data-aos="fade-zoom-in"
                  data-aos-offset="300px"
                  data-aos-delay="700"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
              >
                <TechSpecs
                    title="ELECTRICAL SPECS"
                    description={[
                      { title: "Power supply:  ", description: "12V (unregulated 10-18V)" },
                      { title: "power consumption:  ", description: "Idle: 0.2W\nHOLD: 2W\nACTUATION: 5W" },
                      { title: "power transfer type: ", description: "flex pcb (twist capsule)" },
                      {
                        title: "Maximum power transfer: ",
                        description: "500W @42V",
                      },
                      {
                        title: "Communication interface:  ",
                        description: "RS485 / CAN",
                      },
                    ]}

                />
              </div>
              <div
                  data-aos="fade-zoom-in"
                  data-aos-offset="300px"
                  data-aos-delay="1000"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
              >
                <TechSpecs
                    title="THERMAL SPECS"
                    description={[
                      { title: "Operating  temperature range: ", description: "-40/+60 °C" },
                      { title: "SURVIVAL temperature range: ", description: "-50/+70 °C" },

                    ]}

                />
              </div>
              <div
                  data-aos="fade-zoom-in"
                  data-aos-offset="300px"
                  data-aos-delay="1300"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
              >
                <TechSpecs
                    title="MODES OF OPERATIONS"
                    description={[
                      { title: "Autonomous  ", description: "sun tracking based on sara’s sun sensor data" },
                      { title: "Freeze ", description: "pauses autonomous actuation for a given time" },
                      { title: "Slave  ", description: " target angle set by the on-board computer" },
                      {
                        title: "Safe ",
                        description: " panels are (mechanically) returned to the reference angle by snapback",
                      },
                    ]}

                />
              </div>
            </div>
          </div>
        </div>*/}


        {/* VIDEO Section */}
        {/*<div className="opacity-0 animate-fadeIn delay-0 h-full flex flex-col bg-black bg-opacity-50 z-40">


        <div>
          <div>

            <video
                className="inset-0 w-screen h-full object-cover"
                loop={true}
                autoPlay={false}
                controls
            >
              <source src="/SARA_2.0_DIDASCALIE.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>*/}

        {/*FORM SECTION*/}
        <div className="max-w-md mx-auto mt-0 px-6 "  data-aos="fade-zoom-in"
             data-aos-offset="300px"
             data-aos-delay="0"
             data-aos-easing="ease-in-sine"
             data-aos-duration="600">
          <h1
              id='positions'
              className="opacity-0 animate-fadeIn delay-2 my-12 text-5xl leading-120 tracking-tighter text-center uppercase font-medium text-white ">
            LET'S TALK ABOUT IT!
          </h1>
          <ProductWebForm
              formId="95a0e1ff-e505-4b30-9253-a3f16bc466a6"
              portalId="145047450"
              region="eu1"
          />
        </div>



        <div className="mt-40  z-40 bg-black">
          <Link to="/contact">
            <div className="flex items-center justify-center ">
              <button
                  className=" w-auto h-10 text-center text-white  py-2 px-4 transform transition-transform duration-200 hover:scale-110"
                  style={{
                    background: "linear-gradient(90deg, #000000 0%, #eb6000 100%)",
                  }}
              >
                CONTACT US
              </button>
            </div>
          </Link>
        </div>

      </div>
    </div>
  );
}
