import "../global.css";
import React, { useEffect } from "react";
import {Link} from "react-router-dom";
import PipedriveForm from "../components/PipedriveForm";
import ProductWebForm from "../components/PipedriveForm";
import PageHeader from "../components/PageHeader";
import BlackButtons from "../common/BlackButtons";
import {isMobile, isTablet} from "react-device-detect";

export default function Contact() {
    useEffect(() => {
        if (window.location.hash) {
          let newPath = window.location.pathname + window.location.search;
          if (newPath.endsWith('/')) {
            console.log("termina con /", newPath)
            newPath = newPath.slice(0, -1);
            console.log("newPath", newPath)
          }
          window.history.pushState("", document.title, newPath);
        }
      }, []);

    const contacts = [
        {
            title: "SALES",
            description:
                "commerciale@ribesdigilab.it",
            link: "mailto:commerciale@ribesdigilab.it",
        },
         {
            title: "ASSISTANCE",
            description:
                "ASSISTENZA@RIBESDIGILAB.IT",
            link: "mailto:assistenza@ribesdigilab.it",
        },
        {
            title: "GENERAL CONTACT",
            description:
                "info@ribesdigilab.it",
            link: "mailto:info@ribesdigilab.it",
        }
    ];
    return (
        <div>
          
            <div className="relative flex flex-col h-full mt-20 ">
                {/* BECOME A REVOLVER Section */}

                <div className="h-full flex flex-col   z-40 bg-black">
                    {/* Your content here */}
                    <div
                        style={{ position: "absolute", top: 0, left: 0, width: "100%", zIndex: 10,  pointerEvents: "none" ,height:"100%" }}>
                        <PageHeader title={"GET IN TOUCH"} subtitle={"CURIOUS? REACH OUT, WE ARE ALWAYS HERE TO CHAT!"}/>

                    </div>

                    <div className="flex justify-center pt-72 pb-40"> <BlackButtons to1="/contact/#form-contacts"  description1=" CONTACT US" to2="/careers/#positions" description2="WHERE TO FIND US" /></div>

                </div>
            </div>

            {/* CONTACTS Section */}
            <div className="h-full flex flex-col bg-black  bg-opacity-50 z-50 mb-40 ">
                <h1
                    id='def-contacts'
                    className="opacity-0 animate-fadeIn delay-2 my-12 text-5xl leading-120 tracking-tighter text-center uppercase font-medium text-white ">
                    Or just say hello!{" "}
                </h1>
                <h1 className="opacity-0 animate-fadeIn delay-3 mb-24 -mt-8 text-2xl leading-120 tracking-tighter text-center uppercase font-light text-white ">
                    CLICK ON THE email TO contact us!{" "}
                </h1>
                <div>
                <div
                    data-aos="fade-zoom-in"
                    data-aos-offset="300px"
                    data-aos-delay="0"
                    data-aos-easing="ease-in-sine"
                    data-aos-duration="600"
                    className="flex flex-col gap-4 mt-10 sm:mt-0 z-0 items-center ">                        {" "}
                        {/* Add top margin on mobile */}
                        {contacts.map((contact, index) => (
                            <a
                                key={index}
                                href={contact.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className=""
                            >
                                <div
                                    className="with-border relative group mx-10 p-4 transition-all duration-200 overflow-hidden">
                                    <div
                                        className="absolute  inset-0 transform -scale-x-3 -scale-y-3 group-hover:scale-100 transition-transform duration-200"></div>
                                    <div
                                        className="  relative z-40 flex  sm:flex-row justify-between items-center">
                                        {" "}
                                        {/* Make it a column on mobile */}
                                        <div className=" w-80">
                                            {" "}
                                            {/* Adjust as needed */}
                                            <h2 className="text-lg  text-white uppercase">
                                                {contact.title}
                                            </h2>
                                            <p className="text-sm text-white uppercase">
                                                {contact.description}
                                            </p>
                                        </div>
                                        <div
                                            className="opacity-0 group-hover:opacity-100 transition-opacity duration-200
                                                 absolute right-0 bottom-0"
                                        >
                                            <img
                                                className="h-6 w-6 fill-current text-blue-500"
                                                src="/commons/arrow_forward.svg"
                                                alt="arrow"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </a>
                        ))}
                    </div>
                </div>
            </div>

            <div  id='form-contacts' className="pb-20" ></div>

            {/*FORM SECTION*/}
             <div className="max-w-md mx-auto mt-10 p-6 "  data-aos="fade-zoom-in"
                 data-aos-offset="300px"
                 data-aos-delay="0"
                 data-aos-easing="ease-in-sine"
                 data-aos-duration="600">
                 <h1
                     id='positions'
                     className="opacity-0 animate-fadeIn delay-2 my-12 text-5xl leading-120 tracking-tighter text-center uppercase font-medium text-white ">
                     CONTACT REQUEST{" "}
                 </h1>
                 <ProductWebForm
                     formId="95a0e1ff-e505-4b30-9253-a3f16bc466a6"
                     portalId="145047450"
                     region="eu1"
                 />
            </div>

            {/*MAPS + IMAGE SECTION*/}

            <div
                className="h-full w-full py-40 bg-black bg-center z-40  px-10 flex flex-col items-center justify-center "
                style={{ backgroundImage: `url(/mole.svg)`, backgroundPosition: 'right ', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', height:'screen'}}>

                <div>
                    <h1
                        data-aos="fade-zoom-in"
                        data-aos-offset="300px"
                        data-aos-delay="0"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="600"
                        id='positions'
                        className=" my-12 text-5xl leading-120 tracking-tighter text-center uppercase font-medium text-white mix-blend-difference  ">
                        From turin with love{" "}
                    </h1>
                    <h1
                        data-aos="fade-zoom-in"
                        data-aos-offset="300px"
                        data-aos-delay="400"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="600"
                        className=" mb-24 -mt-8 text-2xl leading-120 tracking-tighter text-center uppercase font-light text-white mix-blend-difference ">
                        BUT YOU COULD ALWAYS FIND US HERE: {" "}
                    </h1>
                </div>




                <div className="grid  grid-cols-2 xl:gap-x-40 md:gap-x-24 text-white mb-20"
                     >

                    <Link to="https://g.co/kgs/2CquFY2" target="_blank" rel="noopener noreferrer" >
                    <button
                        data-aos="fade-zoom-in"
                        data-aos-offset="300px"
                        data-aos-delay="0"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="600"
                        className="col-span-1  pb-5 mix-blend-difference  transform transition-transform duration-200 hover:scale-110 ">

                        <div className="pb-10 h-52">
                            <img src="/contacts/star1.svg" alt=""/>
                        </div>
                        <div className="text-left mix-blend-difference ">
                            <h2 className="font-bold text-2xl uppercase">RIBES digilab srl</h2>
                            <p className="uppercase">Lungo Dora Colletta 81/A <br/> 10153 Torino <br/> Italy</p>
                        </div>
                    </button>
                    </Link>

                    <Link to="https://g.co/kgs/2CquFY2" target="_blank" rel="noopener noreferrer" >
                    <button
                        data-aos="fade-zoom-in"
                        data-aos-offset="300px"
                        data-aos-delay="400"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="600"
                        className="   col-span-1 pb-5 mix-blend-difference  transform transition-transform duration-200 hover:scale-110">
                        <div className="pb-10 h-52">
                            <img src="/contacts/stars3.svg" alt=""/>
                        </div>
                        <div className="text-left">
                            <h2 className="font-bold text-2xl uppercase">Ribes digilab srl</h2>
                            <p className="uppercase">Via Malta 36/13 <br/> 10141 Torino <br/>Italy</p>
                        </div>
                    </button>
                    </Link>

                </div>

                {!isMobile && !isTablet && (<div className=" w-1/3 h-full">
                    <iframe src="https://storage.googleapis.com/maps-solutions-wocgqcgwpv/locator-plus/ylzc/locator-plus.html"
                            width="100%" height="100%"
                            style={{border:0}}
                            loading="eager">
                    </iframe>
                </div>)}
            </div>
        </div>
    );
}
