import React from 'react';
import PipedriveForm from './PipedriveForm';
import ProductWebForm from "./PipedriveForm";

const OverlayForm: React.FC<{ formUrl: string, onClose: () => void }> = ({ formUrl, onClose }) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50">
            <div className="relative bg-black p-8  shadow-lg h-full">
                <button
                    className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
                    onClick={onClose}
                >
                    &times;
                </button>
                <ProductWebForm
                    formId="95a0e1ff-e505-4b30-9253-a3f16bc466a6"
                    portalId="145047450"
                    region="eu1"
                />
            </div>
        </div>
    );
};

export default OverlayForm;
