// src/common/configuratore/GLTFModel.tsx
import React, { useEffect } from "react";
import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";

interface GLTFModelProps {
  modelPath: string;
  position?: [number, number, number];
  scale?: [number, number, number];
  rotation?: [number, number, number];
  castShadow?: boolean;
  receiveShadow?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  onPointerOver?: (event: React.PointerEvent) => void;
  onPointerOut?: (event: React.PointerEvent) => void;
  visible?: boolean;
  name?: string;

  onLoad?: (gltf: GLTF) => void;
}

const GLTFModel: React.FC<GLTFModelProps> = ({
  modelPath,
  position = [0, 0, 0],
  scale = [1, 1, 1],
  rotation = [0, 0, 0],
  castShadow = false,
  receiveShadow = false,
  onClick,
  onPointerOver,
  onPointerOut,
  visible = true,
  name,
 // visibility,

  onLoad,
}) => {
  const gltf: GLTF = useLoader(GLTFLoader, modelPath);





  return (
    <primitive
      object={gltf.scene}
      position={position}
      scale={scale}
      rotation={rotation}
      castShadow={castShadow}
      receiveShadow={receiveShadow}
      onClick={onClick}
      onPointerOver={onPointerOver}
      onPointerOut={onPointerOut}
      //visible={visible}
      name={name}
    />
  );
};

export default GLTFModel;
