import React from "react";

interface SmallNewsProps {
  imageSrc?: string;
  title?: string;
  description?: string;
  category?: string;
  readingTime?: number;
}

const capitalizeFirstLetterOfEachWord = (text = "") => {
  return text
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const SmallNews: React.FC<SmallNewsProps> = ({
  imageSrc,
  title,
  description,
  category,
  readingTime,
}) => {
  return (
    <div className="flex flex-col text-white">
      <img src={imageSrc} alt={title} className="w-full h-48 object-cover" />
      <h2 className="text-md mt-2 pt-2 line-clamp-2 ">{title}</h2>
      <h3 className="text-xs mt-2 line-clamp-2">{description} ...READ MORE</h3>
      <p className="mt-2 text-xs text-white ">
        {capitalizeFirstLetterOfEachWord(category)} | {readingTime} min read
      </p>
    </div>
  );
};

export default SmallNews;
