import React, {useEffect, useMemo, useRef, useState} from "react";
import ImageText from "../common/ImageText";
import "../global.css";
import {Link} from "react-router-dom";
import CareersSlider from "../common/CareersSlider";
import ImageTextCareer from "../common/ImageTextCareer";
import ProductSlider from "../common/ProductSlider";
import GradientButtons from "../common/GradientButtons";
import PageHeader from "../components/PageHeader";
import BlackButtons from "../common/BlackButtons";
import {Canvas, useFrame, useLoader} from "@react-three/fiber";
import {Box, Environment, OrbitControls} from "@react-three/drei";

import ModelsScene from "../common/configuratore/ModelsScene";
import GLTFModel from "../common/configuratore/GLTFModel";
import {SVGLoader} from "three-stdlib";
import {Bloom, EffectComposer, TiltShift} from "@react-three/postprocessing";





export default function Career() {
    useEffect(() => {
        if (window.location.hash) {
          let newPath = window.location.pathname + window.location.search;
          if (newPath.endsWith('/')) {
            console.log("termina con /", newPath)
            newPath = newPath.slice(0, -1);
            console.log("newPath", newPath)
          }
          window.history.pushState("", document.title, newPath);
        }
      }, []);
    // Positions data
    const positions = [
        {
            title: "IT TECHNICIAN",
            description:
                "FULL-TIME",
            location: "Turin (IT)",
            link: "https://it.linkedin.com/company/ribes-digilab",
        },
        {
            title: "sales manager",
            description:
                "FULL-TIME",
            location: "Turin (IT)",
            link: "https://it.linkedin.com/company/ribes-digilab",        },
        {
            title: "open application",
            description:
                "FULL-TIME",
            location: "Turin (IT) / Hybrid",
            link: "https://it.linkedin.com/company/ribes-digilab",        },

        // Add more positions as needed
    ];

    return (
        <div>
            

            <div className="relative flex flex-col h-full   mt-20">


                {/* BECOME A REVOLVER Section */}
                <div className="h-screen flex flex-col   z-40 bg-black">
                    {/* Your content here */}
                    <div className="absolute inset-0  top-0 left-0 w-auto h-screen z-0" >
                        <Canvas dpr={[1, 1.5]} camera={{ fov: 70, position: [0, 2, 5], rotation:[0,0,0] }} style={{ position: "absolute", zIndex: 5 }} className="opacity-0 animate-fadeIn delay-3 ">
                            <color attach="background" args={['#000000']} />
                            <fog attach="fog" args={['#191920', 0, 15]} />

                            <GLTFModel
                                modelPath={'/configuratore/models/satellitetest/people.gltf'}
                                position={[0, -0.2, 0]}
                                scale={[0.1, 0.1, 0.1]}
                                rotation={[0, 0, 0]}
                            />

                            <OrbitControls
                                enablePan={false}
                                minPolarAngle={Math.PI / 2}  // Imposta la rotazione verticale minima
                                maxPolarAngle={Math.PI / 2}  // Blocca la rotazione verticale, limitandola a un singolo piano
                                enableZoom={false}  // Se desideri anche lo zoom
                                enableDamping={true}
                                dampingFactor={0.05}
                                rotateSpeed={0.05}
                            />
                            <ambientLight intensity={1}  />
                            <directionalLight position={[0, 0, -5]} intensity={25} color="#eb6000"/>
                            <directionalLight position={[-10, 0, 2]} intensity={3} color="#00bfff"/>

                            <Environment preset="city" />
                            <EffectComposer>
                                <Bloom intensity={0.05} luminanceThreshold={0.2} luminanceSmoothing={0.5}  />
                                <TiltShift  />
                            </EffectComposer>
                        </Canvas>
                    </div>
                    <div
                        style={{ position: "absolute", top: 0, left: 0, width: "100%", zIndex: 10,  pointerEvents: "none" , height:"100%"}}>
                        <PageHeader title={"join our team"} subtitle={"become a digilaber"}/>

                    </div>


                    <div className="flex justify-center pt-72 pb-40 z-40 flex-col columns-1 "> <BlackButtons to1="/careers/#our-values"  description1=" OUR VALUES" to2="/careers/#positions" description2="APPLY NOW"  /> </div>

                </div>

                {/*WHAT WE VALUE*/}
                <div  className=" mb-24 h-full flex flex-col  z-40">
                    {/* Your content here */}
                    <h1
                        data-aos="fade-zoom-in"
                        data-aos-offset="300px"
                        data-aos-delay="0"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="600"
                        className=" mt-40 mb-12 text-6xl leading-120 tracking-tighter text-center uppercase  text-white z-20 ">
                        WHAT WE VALUE
                    </h1>


                    <div className="mb-8">
                    <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-4 px-4 sm:grid-cols-1 gap-y-20 items-start ">

                        <div className="value-item"
                             data-aos="fade-zoom-in"
                             data-aos-offset="300px"
                             data-aos-delay="400"
                             data-aos-easing="ease-in-sine"
                             data-aos-duration="600">
                                <ImageTextCareer
                                    text="HONESTY"
                                    imageSrc="/careers_icons/careers_transparency.svg"
                                    description="We value directness, transparency, and concreteness. We appreciate a professional and serious demeanor in every interaction"
                                    textAlign="left"
                                />
                            </div>
                            <div className="value-item"
                                 data-aos="fade-zoom-in"
                                 data-aos-offset="300px"
                                 data-aos-delay="800"
                                 data-aos-easing="ease-in-sine"
                                 data-aos-duration="600"
                            >
                                <ImageTextCareer
                                    text="ACCOUNTABILITY"
                                    imageSrc="/careers_icons/careers_responsiveness.svg"
                                    description="WE ARE NOT afraid of saying 'I messed up'. We want to identify obstacles as soon as possible, so that we can work around them fast. "
                                    textAlign="left"
                                />
                            </div>
                            <div className="value-item"
                                 data-aos="fade-zoom-in"
                                 data-aos-offset="300px"
                                 data-aos-delay="1200"
                                 data-aos-easing="ease-in-sine"
                                 data-aos-duration="600"
                            >
                                <ImageTextCareer
                                    text="PROACTIVENESS"
                                    imageSrc="/careers_icons/careers_agility.svg"
                                    description="IN A GROWING COMPANY, EACH PERSON MAKES A DIFFERENCE. WE RELY ON EACH OTHER'S PROACTIVE APPROACH TO KEEP IMPROVING. "
                                    textAlign="left"
                                />
                            </div>
                            <div className="value-item"
                                 data-aos="fade-zoom-in"
                                 data-aos-offset="300px"
                                 data-aos-delay="1600"
                                 data-aos-easing="ease-in-sine"
                                 data-aos-duration="600"
                            >
                                <ImageTextCareer
                                    text="OBJECTIVE-DRIVEN"
                                    imageSrc="/careers_icons/careers_reilability.svg"
                                    description="WE VALUE THE EFFECTS OF OUR WORK, NOT THE TIME SPENT SITTING AT THE DESK. WE EMPOWER EVERY TEAM MEMBER BASED ON THEIR UNIQUE SKILLSET."
                                    textAlign="left"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className=" absolute inset-0 flex items-center  z-0  2xl:opacity-90 lg:opacity-90 md:opacity-90 sm:opacity-90 ">
                    <img
                        className="object-cover h-full w-auto"
                        src="/10.jpg"
                        alt="triangles"
                    />
                </div>

                {/* PHRASE Section */}
                <div className="h-full flex flex-col z-40">
                    {/* Your content here */}

                    <h1
                        id="positions"
                        data-aos="fade-zoom-in"
                        data-aos-offset="300px"
                        data-aos-delay="2000"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="600"
                        className=" mb-0 -mt-8 text-2xl leading-120 tracking-tighter text-center uppercase font-light text-white mx-16 ">
                        At Ribes Digilab, we are dedicated to building a diverse, inclusive and authentic workplace. <br/>If this resonates with you, check out the open positions and join our team!{" "}
                    </h1>

                </div>
                <div id='positions' className="h-10"></div>

                {/* OPEN POSITIONS  Section */}
                <div className="h-full flex flex-col z-40 mx-4">
                    {/* Your content here */}
                    <h1
                        data-aos="fade-zoom-in"
                        data-aos-offset="300px"
                        data-aos-delay="0"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="600"

                        
                        className=" my-12 text-6xl leading-120 tracking-tighter text-center uppercase  text-white ">
                        OPEN POSITIONS{" "}
                    </h1>
                    <h1
                        data-aos="fade-zoom-in"
                        data-aos-offset="300px"
                        data-aos-delay="400"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="600"
                        className=" mb-24 -mt-8 text-2xl leading-120 tracking-tighter text-center uppercase font-light text-white ">
                        CLICK ON A POSITION TO APPLY!{" "}
                    </h1>
                    <div>
                        <div
                            data-aos="fade-zoom-in"
                            data-aos-offset="300px"
                            data-aos-delay="800"
                            data-aos-easing="ease-in-sine"
                            data-aos-duration="600"
                            className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-10 sm:mt-0">
                            {" "}
                            {/* Add top margin on mobile */}
                            {positions.map((position, index) => (
                                <a
                                    key={index}
                                    href={position.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className=""
                                >
                                    <div
                                        className=" with-border relative group   p-4 transition-all duration-200 overflow-hidden bg-black bg-opacity-40 hover:shadow-[0_0_15px_rgba(255,165,0,0.7)] hover:bg-opacity-100">
                                        <div
                                            className="absolute inset-0 transform -scale-x-3 -scale-y-3 group-hover:scale-100 transition-transform duration-200"></div>
                                        <div
                                            className="relative z-10 flex flex-col sm:flex-row justify-between items-left ">
                                            {" "}
                                            {/* Make it a column on mobile */}
                                            <div className="max-w-[calc(100%-1.5rem)]">
                                                {" "}
                                                {/* Adjust as needed */}
                                                <p className="text-sm text-white uppercase">
                                                    {position.description}
                                                </p>
                                                <h2 className="text-lg text-white uppercase">
                                                    {position.title}
                                                </h2>
                                                <p className="text-sm text-white uppercase">
                                                    {position.location}
                                                </p>
                                            </div>
                                            <div
                                                className="opacity-0 group-hover:opacity-100 transition-opacity duration-200
                                                 absolute right-0 bottom-0"
                                            >
                                                <img
                                                    className="h-6 w-6 fill-current text-blue-500"
                                                    src="/commons/arrow_forward.svg"
                                                    alt="arrow"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            ))}
                        </div>
                    </div>
                </div>



                {/* PRODUCT GALLERY Section */}
                 <div className="h-full flex flex-col bg-black  z-40  mt-40">

                <h1
                    data-aos="fade-zoom-in"
                    data-aos-offset="300px"
                    data-aos-delay="0"
                    data-aos-easing="ease-in-sine"
                    data-aos-duration="600"
                    className=" my-40  text-5xl leading-120 tracking-tighter text-center uppercase text-white ">
                    PHOTO GALLERY
                </h1>
WIP :)
                     {/* <div
                    className="mb-60"
                    data-aos="fade-zoom-in"
                    data-aos-offset="300px"
                    data-aos-delay="400"
                    data-aos-easing="ease-in-sine"
                    data-aos-duration="600">

                    <CareersSlider />
                </div>*/}
            </div>

                <div className="  z-40 bg-black">
                    <Link to="/contact">
                        <div className="flex items-center justify-center ">
                            <button
                                className=" w-auto h-10 text-center text-white  py-2 px-4 transform transition-transform duration-200 hover:scale-110"
                                style={{
                                    background: "linear-gradient(90deg, #000000 0%, #eb6000 100%)",
                                }}
                            >
                                CONTACT US
                            </button>
                        </div>
                    </Link>
                </div>



            </div>
        </div>
    );
}
