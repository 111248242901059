import React from "react";
import { Link, useParams } from "react-router-dom";
import projectsData from "../Projects.json"; // Path to JSON file

const PreviousNextProjects = () => {
    const { nomeprogetto } = useParams<{ nomeprogetto: string }>();
    const currentIndex = projectsData.findIndex((proj) => proj.id === nomeprogetto);

    // Handle edge cases to loop around
    const prevIndex = (currentIndex - 1 + projectsData.length) % projectsData.length;
    const nextIndex = (currentIndex + 1) % projectsData.length;

    return (
       <div className="flex flex-row justify-between mx-4">
           <div className="">
               <Link to={`/item/${projectsData[prevIndex].id}`} className="text-white">

                   <button
                       className=" w-auto h-10 text-center text-white  py-2 px-4 transform transition-transform duration-200 hover:scale-110"
                       style={{
                           background: "linear-gradient(90deg, #000000 0%, #eb6000 100%)",
                       }}
                   >  Previous: {projectsData[prevIndex].title} </button>
               </Link>

           </div>
           <div className="">
               <Link to={`/item/${projectsData[nextIndex].id}`} className="text-white">
                   <button
                       className=" w-auto h-10 text-center text-white  py-2 px-4 transform transition-transform duration-200 hover:scale-110"
                       style={{
                           background: "linear-gradient(90deg, #000000 0%, #eb6000 100%)",
                       }}
                   >

                       Next: {projectsData[nextIndex].title} </button>
               </Link>
           </div>
       </div>

    );
};

export default PreviousNextProjects;
