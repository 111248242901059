import React from "react";

type HeadingAndSubtitleProps = {
  heading: string;
  subtitle: string;
};

const HeadingAndSubtitle: React.FC<HeadingAndSubtitleProps> = ({
  heading,
  subtitle,
}) => {
  return (
    <div className="text-white">
      <h1 className="text-lg">{heading}</h1>
      <p className="text-gray-400 text-md">{subtitle}</p>
    </div>
  );
};

export default HeadingAndSubtitle;
