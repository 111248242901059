import React from "react";

interface ClientLogo {
    id: number;
    imageUrl: string;
    altText: string;
}

const clientLogos: ClientLogo[] = [
    { id: 1, imageUrl: "/clients/logo-iveco.png", altText: "Iveco" },
    { id: 2, imageUrl: "/clients/logo-cnh.svg", altText: "CNH Industrial" },
    { id: 3, imageUrl: "/clients/logo-comau.png", altText: "CRF" },
    { id: 4, imageUrl: "/clients/logo-ferrero.svg", altText: "Ferrero" },
    { id: 5, imageUrl: "/clients/logo-stellantis.png", altText: "Stellantis" },
    { id: 6, imageUrl: "/clients/logo-lavazza.png", altText: "Lavazza" },
    { id: 7, imageUrl: "/clients/logo-egizio.png", altText: "Museo Egizio" },
    { id: 8, imageUrl: "/clients/logo-maserati.png", altText: "Maserati" },
    { id: 9, imageUrl: "/clients/logo-museo-cinema.png", altText: "Museo Nazionale del Cinema" },
    { id: 10, imageUrl: "/clients/logo-musei-reali-torino.svg", altText: "Musei Reali Torino" },
    { id: 11, imageUrl: "/clients/logo-artissima.png", altText: "Artissima" },
    { id: 12, imageUrl: "/clients/logo-mauto.png", altText: "MAUTO" },
    { id: 13, imageUrl: "/clients/logo-juventus.png", altText: "Juventus" },
    { id: 14, imageUrl: "/clients/logo-fondazionesanpaolo.png", altText: "Fondazione Compagnia di San Paolo" },
    { id: 15, imageUrl: "/clients/logo-gam-1.png", altText: "GAM Torino" },
    { id: 16, imageUrl: "/clients/logo-museo-torino.jpg", altText: "Museo Torino" },
    { id: 17, imageUrl: "/clients/logo-rivoli.png", altText: "Castello di Rivoli" },
    { id: 18, imageUrl: "/clients/logo-maison-musee-berton.png", altText: "Maison Musée Berton" },
    { id: 19, imageUrl: "/clients/logo-museo-montagna.png", altText: "Museo Nazionale della Montagna" },
    { id: 20, imageUrl: "/clients/logo-palazzo-mazzetti.jpeg", altText: "Palazzo Mazzetti" },
    { id: 21, imageUrl: "/clients/logo-pinacoteca-albertina.png", altText: "Pinacoteca Albertina di Torino" },
    { id: 22, imageUrl: "/clients/logo-opificio.png", altText: "Opificio delle Pietre Dure SAFS" },
    { id: 23, imageUrl: "/clients/logo-citec.svg", altText: "CITEC" },
    { id: 24, imageUrl: "/clients/logo-federlegnoarredo.png", altText: "Federlegnoarredo (FLA)" },
    { id: 25, imageUrl: "/clients/logo-officina-delle-idee.png", altText: "Officina delle Idee" },
    { id: 26, imageUrl: "/clients/logo-awe-sport.png", altText: "AWE Sport" },
    { id: 27, imageUrl: "/clients/logo-borgo-medievale.png", altText: "Borgo Medievale Torino" },
    { id: 28, imageUrl: "/clients/logo-palazzo-reale-genova.png", altText: "Museo Palazzo Reale di Genova" },
    { id: 29, imageUrl: "/clients/logo-accademia-albertina.png", altText: "Accademia Albertina di Belle Arti di Torino" },
    { id: 30, imageUrl: "/clients/logo-museo-civico-ivrea.png", altText: "Museo Civico Pier Alessandro Garda di Ivrea" },
    { id: 31, imageUrl: "/clients/logo-accorsi-ometto.png", altText: "Fondazione Accorsi-Ometto" },
    { id: 32, imageUrl: "/clients/logo-wine-museum.webp", altText: "Wine Museum (Castello di Barolo)" },
    { id: 33, imageUrl: "/clients/logo-roveri.png", altText: "Royal Park Roveri" },
    { id: 34, imageUrl: "/clients/logo-direzione-regionale-musei.png", altText: "Direzione Regionale Musei Piemonte" },
    { id: 35, imageUrl: "/clients/logo-venaria.svg", altText: "La Venaria Reale" },
    { id: 36, imageUrl: "/clients/logo-consulta-torino.png", altText: "Consulta Valorizzazione Beni Artistici e Culturali di Torino" },
    { id: 37, imageUrl: "/clients/logo-mart.png", altText: "MART" },
    { id: 38, imageUrl: "/clients/logo-palazzo-mazzetti-asti.png", altText: "Palazzo Mazzetti di Asti" },
    { id: 39, imageUrl: "/clients/logo-vbac.png", altText: "Biblioteca Nazionale Centrale di Roma" },
    { id: 41, imageUrl: "/clients/logo-rbm.png", altText: "Relevant Business Matters (RBM)" },
    { id: 42, imageUrl: "/clients/logo-joy.avif", altText: "Fondazione Joy Gioielli" },
    { id: 43, imageUrl: "/clients/logo-museo-paesaggio-sonoro.svg", altText: "Museo del Paesaggio Sonoro" },
    { id: 44, imageUrl: "/clients/logo-musli.png", altText: "MUSLI (Museo della Scuola e del Libro per l'Infanzia)" }

];

const ClientGrid: React.FC = () => {
    return (
        <div className="flex flex-col relative space-y-10 pt-20">
            <div className="flex items-center w-full">
                <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-5 gap-6 text-white w-full  ">
                    <div className="col-span-full flex justify-center pb-16">
                        <h1 className="text-4xl md:text-6xl uppercase">OUR CLIENTS</h1>
                    </div>
                    {clientLogos.map((logo) => (
                        <div
                            key={logo.id}
                            className="flex flex-col items-center my-4"
                            data-aos="fade-zoom-in"
                            data-aos-offset="200"
                            data-aos-delay={`${300 + logo.id * 100}`}
                            data-aos-easing="ease-in-sine"
                            data-aos-duration="600"
                            data-aos-once="true"
                        >
                            <div className="flex items-center justify-center w-full h-full">
                                <img
                                    className="object-cover h-5 sm:h-7 md:h-10 w-auto hover:scale-110 "
                                    src={logo.imageUrl}
                                    alt={logo.altText}
                                    style={{ filter: "brightness(0) invert(1)" }} // Makes the logo white
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ClientGrid;
