import React, {useEffect, useState} from "react";
import "../global.css";
import {Link, useLocation} from "react-router-dom";

import {MyThreeScene} from "../common/scena/MyThreeScene";
import SectionsHeader from "../components/SectionsHeader";
import BlackButtons from "../common/BlackButtons";
import {Environment, OrbitControls} from "@react-three/drei";
import {Bloom, EffectComposer, TiltShift} from "@react-three/postprocessing";
import { Canvas } from "react-three-fiber";
import GLTFModel from "../common/configuratore/GLTFModel";
import {isMobile, isTablet} from "react-device-detect";
import PageHeader from "../components/PageHeader";

export default function HomeTemp() {
    const location = useLocation()
    const [modelPath, setModelPath] = useState<string | null>(null);
    const [isHome, setIsHome] = useState<boolean>(false);

    useEffect(() => {
        if (window.location.hash) {
            let newPath = window.location.pathname + window.location.search;
            if (newPath.endsWith("/")) {
                console.log("termina con /", newPath);
                newPath = newPath.slice(0, -1);
                console.log("newPath", newPath);
            }
            window.history.pushState("", document.title, newPath);
        }
        window.onload = () => {
            window.scrollTo(0, 0);
        };
    }, []);

    useEffect(() => {
        // Check if the current path is home or root
        if (location.pathname === "/home" || location.pathname === "/") {
            setIsHome(true);
        } else {
            setIsHome(false);
        }
    }, [location.pathname]);

    useEffect(() => {
        // Update the body's overflow style based on isHome state
        document.body.style.overflow = isHome ? "hidden" : "scroll";
        return () => {
            // Clean up when the component unmounts
            document.body.style.overflow = "scroll";
        };
    }, [isHome]);

// Function to create Pexels image URL
    const pexel = (id: number) => `https://images.pexels.com/photos/${id}/pexels-photo-${id}.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260`;
    const custom = (id: string) => `./photos/${id}.jpg`;

// Updated images array with Pexels URLs
    const imageData: { position: [number, number, number]; rotation: [number, number, number]; url: string; name: string }[] = [
        { position: [0, 0, 1.5], rotation: [0, 0, 0], url: custom('martini'), name: "Progetto Martini" }, // Front
        { position: [-0.8, 0, -0.6], rotation: [0, 0, 0], url: custom('galleria-della-scrittura'), name: "Galleria della Scrittura" }, // Back
        { position: [0.8, 0, -0.6], rotation: [0, 0, 0], url: pexel(310452), name: "Progetto 310452" }, // Back
        { position: [-1.75, 0, 0.25], rotation: [0, Math.PI / 2.5, 0], url: pexel(327482), name: "Progetto 310452" }, // Left
        { position: [-2.15, 0, 1.5], rotation: [0, Math.PI / 2.5, 0], url: pexel(325185), name: "Progetto 310452" }, // Left
        { position: [-2, 0, 2.75], rotation: [0, Math.PI / 2.5, 0], url: pexel(358574), name: "Progetto 310452" }, // Left
        { position: [1.75, 0, 0.25], rotation: [0, -Math.PI / 2.5, 0], url: pexel(227675), name: "Progetto 310452" }, // Right
        { position: [2.15, 0, 1.5], rotation: [0, -Math.PI / 2.5, 0], url: pexel(911738), name: "Progetto 310452" }, // Right
        { position: [2, 0, 2.75], rotation: [0, -Math.PI / 2.5, 0], url: pexel(1738986), name: "Progetto 310452" }, // Right
        { position: [2, 0, 3.75], rotation: [0, -Math.PI / 2.5, 0], url: pexel(1738986), name: "Progetto 310452" }, // Right
        { position: [2, 0, 4.75], rotation: [0, -Math.PI / 2.5, 0], url: pexel(1738986), name: "Progetto 310452" }, // Right
        { position: [2, 0, 5.75], rotation: [0, -Math.PI / 2.5, 0], url: pexel(1738286), name: "Progetto 310452" }, // Right
        { position: [2, 0, 6.75], rotation: [0, -Math.PI / 2.5, 0], url: pexel(1738186), name: "Progetto 310452" }, // Right
    ];



    return (
        <div >
            <div className="relative flex flex-col h-full  mt-20">
                {/* TITLE Section */}
                <div className="h-screen flex flex-col   z-40 opacity-0 animate-fadeIn delay-1  ">
                    <div className="absolute inset-0  top-0 left-0 w-auto h-screen z-0 opacity-70 " >
                        {/*<img src="/projects/MARTINI LEDWALL/CASA_MARTINI_0.jpg" alt="sara" className="object-cover w-full h-full" />*/}

                    </div>

                    {!isMobile && !isTablet &&
                        <Canvas dpr={[1, 1.5]} camera={{ fov: 70, position: [0, 2, 5], rotation:[0,0,0] }} style={{ position: "absolute", zIndex: 5 }} className="opacity-0 animate-fadeIn delay-3 ">
                        <color attach="background" args={['#000000']} />
                        <fog attach="fog" args={['#191920', 0, 15]} />

                        <GLTFModel
                            modelPath={'/configuratore/models/satellitetest/priest.gltf'}
                            position={[3, -0.2, 0]}
                            scale={[0.1, 0.1, 0.1]}
                            rotation={[0, Math.PI, 0]}
                        />
                        <GLTFModel
                        modelPath={'/configuratore/models/satellitetest/priest1.gltf'}
                        position={[-3, -0.2, 0]}
                        scale={[0.1, 0.1, 0.1]}
                        rotation={[0,  Math.PI /4, 0]}
                    />

                        <OrbitControls
                            enablePan={false}
                            minPolarAngle={Math.PI / 2}  // Imposta la rotazione verticale minima
                            maxPolarAngle={Math.PI / 2}  // Blocca la rotazione verticale, limitandola a un singolo piano
                            enableZoom={false}  // Se desideri anche lo zoom
                            enableDamping={true}
                            dampingFactor={0.05}
                            rotateSpeed={0.5}
                        />
                        <ambientLight intensity={0.3}  />
                        <directionalLight position={[0, 2, -5]} intensity={20} color="#eb6000"/>
                        <directionalLight position={[-10, 0, 2]} intensity={3} color="#00bfff"/>

                       <><Environment preset="city" />

                            <EffectComposer>
                                <Bloom intensity={0.05} luminanceThreshold={0.2} luminanceSmoothing={0.5}  />
                                <TiltShift  />
                            </EffectComposer></>
                    </Canvas>}

                    <div className="flex flex-col">
                        <SectionsHeader title={"Experience Innovation Firsthand"} subtitle={"see how we transform ideas into immersive realities."}/>
                        {/*<SectionsHeader title={"Experience Innovation Firsthand"} subtitle={"see how we transform ideas into immersive realities."}/>
*/}
                        <div className="absolute inset-0 h-screen w-auto">
                            <div className="relative flex justify-center items-center h-screen w-auto pt-20 z-40"> <BlackButtons to1="/about"  description1="what we do" to2="/services/cultural-heritage" description2="SERVICES" /></div>

                        </div>
                    </div>
                </div>
                </div>


        </div>
    );
}
