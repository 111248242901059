import React from "react";

type MainStructureNewsProps = {
  heading_1: string;
  subtitle_1: string;
  quote_1: string;
  quote_author_1: string;
  image_1: string;

  heading_2: string;
  subtitle_2: string;
  quote_2: string;
  quote_author_2: string;
  image_2: string;

  heading_3: string;
  subtitle_3: string;
  quote_3: string;
  quote_author_3: string;
  image_3: string;

  heading_4: string;
  subtitle_4: string;
  quote_4: string;
  quote_author_4: string;
  image_4: string;

  heading_5: string;
  subtitle_5: string;
  quote_5: string;
  quote_author_5: string;
  image_5: string;
};

const MainStructureNews: React.FC<MainStructureNewsProps> = ({
  heading_1,
  subtitle_1,
  quote_1,
  quote_author_1,
  image_1,

  heading_2,
  subtitle_2,
  quote_2,
  quote_author_2,
  image_2,

  heading_3,
  subtitle_3,
  quote_3,
  quote_author_3,
  image_3,

  heading_4,
  subtitle_4,
  quote_4,
  quote_author_4,
  image_4,

  heading_5,
  subtitle_5,
  quote_5,
  quote_author_5,
  image_5,
}) => {
  return (
    <div className="text-white space-y-10">
      <div className="space-y-5">
        {heading_1 && <h1 className="text-lg">{heading_1}</h1>}
        {subtitle_1 && <p className="text-gray-400 text-md">{subtitle_1}</p>}
        <div>
          {quote_1 && <h2 className="text-lg italic font-bold">{quote_1}</h2>}
          {quote_author_1 && (
            <p className="text-gray-400 text-sm">{quote_author_1}</p>
          )}
        </div>

        {image_1 && (
          <img
            className="w-auto h-64 object-cover pt-10"
            src={image_1 ? image_1 : ""}
            alt="image 1"
          />
        )}
      </div>
      <div className="space-y-5">
        {heading_2 && <h1 className="text-lg">{heading_2}</h1>}
        {subtitle_2 && <p className="text-gray-400 text-md">{subtitle_2}</p>}
        <div>
          {quote_2 && <h2 className="text-lg italic font-bold">{quote_2}</h2>}
          {quote_author_2 && (
            <p className="text-gray-400 text-sm">{quote_author_2}</p>
          )}
        </div>

        {image_2 && (
          <img
            className="w-auto h-64 object-cover pt-10"
            src={image_2 ? image_2 : ""}
            alt="image 2"
          />
        )}
      </div>
      <div className="space-y-5">
        {heading_3 && <h1 className="text-lg">{heading_3}</h1>}
        {subtitle_3 && <p className="text-gray-400 text-md">{subtitle_3}</p>}
        <div>
          {quote_3 && <h2 className="text-lg italic font-bold">{quote_3}</h2>}
          {quote_author_3 && (
            <p className="text-gray-400 text-sm">{quote_author_3}</p>
          )}
        </div>

        {image_3 && (
          <img
            className="w-auto h-64 object-cover pt-10"
            src={image_3 ? image_3 : ""}
            alt="image 3"
          />
        )}
      </div>
      <div className="space-y-5">
        {heading_4 && <h1 className="text-lg">{heading_4}</h1>}
        {subtitle_4 && <p className="text-gray-400 text-md">{subtitle_4}</p>}
        <div>
          {quote_4 && <h2 className="text-lg italic font-bold">{quote_4}</h2>}
          {quote_author_4 && (
            <p className="text-gray-400 text-sm">{quote_author_4}</p>
          )}
        </div>

        {image_4 && (
          <img
            className="w-auto h-64 object-cover pt-10"
            src={image_4 ? image_4 : ""}
            alt="image 4"
          />
        )}
      </div>
      <div className="space-y-5">
        {heading_5 && <h1 className="text-lg">{heading_5}</h1>}
        {subtitle_5 && <p className="text-gray-400 text-md">{subtitle_5}</p>}
        <div>
          {quote_5 && <h2 className="text-lg italic font-bold">{quote_5}</h2>}
          {quote_author_5 && (
            <p className="text-gray-400 text-sm">{quote_author_5}</p>
          )}
        </div>
        {image_5 && (
          <img
            className="w-auto h-64 object-cover pt-10"
            src={image_5 ? image_5 : ""}
            alt="image 5"
          />
        )}
      </div>
    </div>
  );
};

export default MainStructureNews;
