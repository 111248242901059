import React from "react";

type SquareNewsProps = {
  publicationDate: string;
  category: string;
  readingTime: string;
  authorName: string;
};

const SquareNews: React.FC<SquareNewsProps> = ({
  publicationDate,
  category,
  readingTime,
  authorName,
}) => {
  return (
    <div
      className="p-0.5 justify-center flex with-border"
      // style={{
      //   position: "relative",
      //   boxShadow: "0 0 0 3px transparent", // Crea spazio per il "bordo" finto
      //   backgroundImage: "linear-gradient(to right, #E6007E, #00858F)", // Gradiente dal rosa al blu
      //   backgroundClip: "padding-box", // Applica il gradiente solo all'area del padding, simulando un bordo
      //   borderRadius: "4px", // Opzionale: aggiunge bordi arrotondati se desiderato
      // }}
    >
      <div
        className="grid grid-rows-2 grid-cols-2 h-full items-center bg-black  gap-7 py-6 px-6"
        style={{
          position: "relative",
          boxShadow: "0 0 0 3px transparent", // Crea spazio per il "bordo" finto
          backgroundClip: "padding-box", // Applica il gradiente solo all'area del padding, simulando un bordo
          borderRadius: "4px", // Opzionale: aggiunge bordi arrotondati se desiderato
        }}
      >
        <div>
          <p className="text-sm font-semibold">Publication Date</p>
          <p className="text-sm">{publicationDate}</p>
        </div>
        <div className="text-right">
          <p className="text-sm font-semibold">Category</p>
          <p className="text-sm">{category}</p>
        </div>
        <div>
          <p className="text-sm font-semibold">Reading Time</p>
          <p className="text-sm">{readingTime} min read</p>
        </div>
        <div className="text-right">
          <p className="text-sm font-semibold">Author Name</p>
          <p className="text-sm">{authorName}</p>
        </div>
      </div>
    </div>
  );
};

export default SquareNews;
