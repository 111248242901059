import React from "react";
import { Link } from "react-router-dom";
import NewsletterForm from "../components/NewsletterForm";
import ProductForm from "../components/ProductForm";
import PipedriveForm from "../components/PipedriveForm";
import LeadForm from "../components/LeadForm";

const Footer: React.FC = () => {
  return (
    <div className="bg-black">
      {/* <NewsletterForm/>
            <PipedriveForm formUrl="https://webforms.pipedrive.com/f/72sjlnxg8yT7or0rAmWhSbltuXooqvkv8FnNiHPKxwM8y2QSxCNHuk3U2scGpqE5Yn"/>
            <PipedriveForm formUrl="https://webforms.pipedrive.com/f/cs5ar6a5ZlhoJ5o3htH9CTN6HuVDKofOQ0zBr3yEb9bNHHO9YsEALWl07mUCm49xGb"/>*/}
      {/*  <ProductForm title={"product sara"} />*/}

      <footer className="grid grid-cols-4 gap-4 py-4 pt-32  text-white">
        <div className="flex flex-col  items-start space-y-4 px-8 ">
          <Link to="/home">
            <img
                className="h-12 w-auto "
                src="/logobiancoribes.png"
                alt="Ribes Digilab Logo"
            />
          </Link>
          <div className="flex ">
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to="https://it.linkedin.com/company/ribes-digilab"
            >
              <img src="/footer_icons/Group.svg" alt="Footer 2" />
            </Link>

            {/*<img src="\footer_icons\path1009.svg" alt="Footer 3" />*/}
          </div>
        </div>

        <div className="grid grid-cols-1 col-span-3 sm:flex sm:flex-row  space-y-4 sm:space-y-0 sm:space-x-4 justify-between px-6">
          <div>
            <a href="/home" className="block underline">
              HOME
            </a>
            <a href="/about" className="block underline">
              ABOUT
            </a>
            <a href="/careers" className="block underline">
              CAREERS
            </a>
            <a href="/clients" className="block underline ">
              TRUSTED BY
            </a>
            <a href="/contact" className="block underline ">
              GET IN TOUCH
            </a>
          </div>


          <div>
            <h2 className="text-1xl  mb-2 uppercase ">
              ribes digilab srl
            </h2>
            <p className="uppercase">Lungo Dora Colletta 81/A <br/> 10153 Torino <br/> Italy</p>
          </div>
          <div>
            <h2 className="text-1xl  mb-2 uppercase">
              administrative address
            </h2>
            <p className="uppercase">Via Malta 36/13 <br/> 10141 Torino <br/>Italy</p>

          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
