import React from 'react';
import { Link } from 'react-router-dom';

interface BlackButtonProps {
    to1: string;
    to2: string;
    description1: string;
    description2: string;
    aosOffset?: number;
    aosDelay?: number;
    aosDuration?: number;
    aosEasing?: string;
    onClick?: any;
}

const BlackButtons: React.FC<BlackButtonProps> = ({ to1, to2, description1, description2, aosOffset, aosDelay, aosDuration, aosEasing, onClick }) => {
    return (
        <div
            className=" opacity-0 animate-fadeIn delay-2 flex flex-col  sm:flex-row justify-center items-center gap-2 md:gap-24 py-12 font-bold "

        >
        <Link to={to1} onClick={onClick}>
            <button
                className="w-auto h-10 text-white px-4 py-2 text-md uppercase transform transition-transform duration-200 hover:scale-110 bg-black hover:text-white border-2 bg-opacity-40 hover:bg-opacity-100  hover:shadow-[0_0_15px_rgba(255,165,0,0.7)] "

            >
                {description1}
            </button>
        </Link>
            <Link to={to2} onClick={onClick}>
            <button
                className="w-auto h-10 text-white px-4 py-2 text-md uppercase transform transition-transform duration-200 hover:scale-110 bg-black hover:text-white border-2 bg-opacity-40 hover:bg-opacity-100  hover:shadow-[0_0_15px_rgba(255,165,0,0.7)] "

            >
                {description2}
            </button>
        </Link>

        </div>
    );
};

export default BlackButtons;