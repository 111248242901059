import React, { useEffect } from 'react';

interface ProductWebFormProps {
    formId: string;
    portalId: string;
    region: string;
}

const ProductWebForm: React.FC<ProductWebFormProps> = ({ formId, portalId, region }) => {
    useEffect(() => {
        // Create script element
        const script = document.createElement('script');
        script.src = "//js-eu1.hsforms.net/forms/embed/v2.js"; // HubSpot form embed script
        script.async = true;

        script.onload = () => {
            // Create the form once the script is loaded
            (window as any).hbspt.forms.create({
                region,
                portalId,
                formId,
                target: '.hubspotWebForms' // Target the div where the form will be loaded
            });
        };

        // Append script to body or specific container
        document.body.appendChild(script);

        // Cleanup script if component is unmounted
        return () => {
            document.body.removeChild(script);
        };
    }, [formId, portalId, region]);

    return (
        <div className="hubspotWebForms">
            {/* The form will be loaded here by the script */}
        </div>
    );
};

export default ProductWebForm;
