import React, { useState, useEffect } from 'react';
import { isMobile, isTablet } from "react-device-detect";

const CustomCircle: React.FC = () => {
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [isHoveringText, setIsHoveringText] = useState(false);
    const [isHidden, setIsHidden] = useState(false);

    // Funzione per tracciare la posizione del mouse
    useEffect(() => {
        const handleMouseMove = (event: MouseEvent) => {
            setPosition({ x: event.clientX, y: event.clientY });

            // Verifica se il mouse è sopra un elemento h1, h2, h3 o p
            const target = event.target as HTMLElement;
            if (target.tagName === 'H1' || target.tagName === 'H2' || target.tagName === 'H3' || target.tagName === 'P') {
                setIsHoveringText(true);
            } else {
                setIsHoveringText(false);
            }

            // Nascondi il triangolo se il mouse è sopra img o video
            if (target.tagName === 'IMG' || target.tagName === 'VIDEO') {
                setIsHidden(true);
            } else {
                setIsHidden(false);
            }

            // Ridimensiona a default se sopra un button
            if (target.tagName === 'BUTTON') {
                setIsHoveringText(true);
            }
        };

        window.addEventListener('mousemove', handleMouseMove);
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return (
        <>
            {!isMobile && !isTablet && (
                <>
                    <div
                        className="fixed top-0 left-0 w-16 h-14 bg-white mix-blend-difference pointer-events-none z-50"
                        style={{
                            transform: `translate(${position.x - 32}px, ${position.y - 32}px) scale(${isHoveringText ? 1 : 0.33})`,
                            clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)', // Forma di un triangolo
                            transition: 'transform 0.1s ease-out',
                        }}
                    ></div>
                </>
            )}
        </>
    );
};

export default CustomCircle;
