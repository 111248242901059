import React from 'react';



interface ImageTextProps {
    text?: string;
    imageSrc: string;
    description?: string;
    textAlign?: 'left' | 'center' | 'right';
    reverse?: boolean;
}

class ImageTextHome extends React.Component<ImageTextProps> {
    render() {
        const { text, imageSrc, description, textAlign = 'center' , reverse } = this.props;

        return (

            <div  className={classNames(
                    reverse
                    ? "relative flex flex-row justify-start  space-y-0 h-full hover:scale-110 transform transition-transform duration-300 ease-in-out "
                    : "relative flex flex-row-reverse justify-end  space-y-0 h-full hover:scale-110 transform transition-transform duration-300 ease-in-out"
                )} >

                <div className="flex flex-col  ">

                    <p style={{textAlign: textAlign}} className="relative bottom-0  text-white uppercase  text-3xl font-montserrat font-bold lg:-w-3/4">{text}</p>
                    {description && <p style={{textAlign: textAlign}} className=" uppercase text-white lg:-w-4/3 h-auto  text-xl font-montserrat font-normal">{description}</p>}
                </div>
                <div className="w-full border-t-4 border-white mt-4">
                    <img src={imageSrc} alt={text} className=" object-center " />
                </div>

            </div>
        );
    }
}

export default ImageTextHome;

function classNames(...classes: (string | boolean)[]) {
    return classes.filter(Boolean).join(" ");
}