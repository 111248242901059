import ImageText from "../common/ImageText";
import Counter from "../common/Counter";
import ProductDescription from "../common/ProductDescription";
import Collapsible from "../common/Collapsible";
import TechSpecs from "../common/TechSpecs";
import ProductSlider from "../common/ProductSlider";
import EmailPopup from '../common/EmailPopup';
import "../global.css";
import { Link } from "react-router-dom";
import React, {useEffect, useState} from "react";
import OverlayForm from "../components/OverlayForm";
import {Canvas} from "@react-three/fiber";
import GLTFModel from "../common/configuratore/GLTFModel";
import {Environment, OrbitControls} from "@react-three/drei";
import {Bloom, EffectComposer, TiltShift} from "@react-three/postprocessing";
import PageHeader from "../components/PageHeader";
import BlackButtons from "../common/BlackButtons";
import ImageTextHome from "../common/ImageText_Home";
import CareersSlider from "../common/CareersSlider";
import HeritageSlider from "../common/HeritageSlider";
import ProductWebForm from "../components/PipedriveForm";
import SectionsHeader from "../components/SectionsHeader";

export default function Heritage40() {

  useEffect(() => {
    if (window.location.hash) {
      let newPath = window.location.pathname + window.location.search;
      if (newPath.endsWith('/')) {
        console.log("termina con /", newPath)
        newPath = newPath.slice(0, -1);
        console.log("newPath", newPath)
      }
      window.history.pushState("", document.title, newPath);
    }
  }, []);

  const [isPopupOpen, setIsPopupOpen] = useState(false);


  const handleRequestClick = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div>

      <div className="relative flex flex-col h-full  mt-20">
        {/* TITLE Section */}
        <div className="h-screen flex flex-col   z-40 opacity-0 animate-fadeIn delay-1 ">
          <div className="absolute inset-0  top-0 left-0 w-auto h-screen z-0 opacity-80" >


            <img src="/projects/MUSEO EGIZIO GALLERIA DELLA SCRITTURA/img_2.png" alt="img" className="object-cover w-full h-full " />
            {/*            <Canvas dpr={[1, 1.5]} camera={{ fov: 70, position: [0, 2, 5], rotation:[0,0,0] }} style={{ position: "absolute", zIndex: 5 }} className="opacity-0 animate-fadeIn delay-3 ">
              <color attach="background" args={['#000000']} />
              <fog attach="fog" args={['#191920', 0, 15]} />

              <GLTFModel
                  modelPath={'/configuratore/models/satellitetest/priest.gltf'}
                  position={[3, -0.2, 0]}
                  scale={[0.1, 0.1, 0.1]}
                  rotation={[0, Math.PI + Math.PI / 2 , 0]}
              />  <GLTFModel
                modelPath={'/configuratore/models/satellitetest/sobek.gltf'}
                position={[-3, -0.2, 0]}
                scale={[0.4, 0.4, 0.4]}
                rotation={[0, Math.PI + Math.PI / 4 , 0]}
            /><GLTFModel
                modelPath={'/configuratore/models/satellitetest/car.gltf'}
                position={[0, -0.8, 3]}
                scale={[0.5, 0.5, 0.5]}
                rotation={[0, Math.PI , 0]}
            /><GLTFModel
                modelPath={'/configuratore/models/satellitetest/cinema.gltf'}
                position={[0, -0.2, -3]}
                scale={[0.5, 0.5, 0.5]}
                rotation={[0, Math.PI , 0]}
            />

              <OrbitControls
                  enablePan={false}
                  minPolarAngle={Math.PI / 2}  // Imposta la rotazione verticale minima
                  maxPolarAngle={Math.PI / 2}  // Blocca la rotazione verticale, limitandola a un singolo piano
                  enableZoom={false}  // Se desideri anche lo zoom
                  enableDamping={true}
                  dampingFactor={0.05}
                  rotateSpeed={0.5}
              />
              <ambientLight intensity={0.3}  />
              <directionalLight position={[0, 2, -5]} intensity={20} color="#eb6000"/>
              <directionalLight position={[-10, 0, 2]} intensity={3} color="#00bfff"/>

              <Environment preset="city" />
              <EffectComposer>
                <Bloom intensity={0.05} luminanceThreshold={0.2} luminanceSmoothing={0.5}  />
                <TiltShift  />
              </EffectComposer>
            </Canvas>
*/}          </div>


        <div className="flex flex-col">
          <SectionsHeader title={"CULTURAL HERITAGE 4.0"} subtitle={"Representing the Past with Innovative Technology"}/>

          <div className="absolute inset-0 h-screen w-auto">
            <div className="relative flex justify-center items-center h-screen w-auto pt-20 z-40"> <BlackButtons to1="/services/cultural-heritage/#infos"  description1="what we do" to2="/services/cultural-heritage/#gallery" description2="CASE HISTORY" /></div>

          </div>
        </div>
        </div>

        <div className="mx-12 mb-24 h-full flex flex-col bg-opacity-50 z-40 pt-10">
          <div>
            <div className="grid  md:grid-rows-2 gap-4 sm:grid-cols-1 gap-y-20 ">
              <div
                  className="value-item"
                  data-aos="zoom-out-up"
                  data-aos-offset="100"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
              >
                <ImageTextHome
                    text="WHAT WE DO"
                    imageSrc="/LogoRibes_In_Out - Copy.gif"
                    description="We preserve cultural heritage by making it accessible and engaging for future generations. Through innovative multimedia tools, we redefine how people interact with technology, fostering a more sustainable and productive world. We understand curators and their needs, designing custom software solutions that enhance cultural assets by identifying the most suitable media. We craft interactive narratives, both linear and non-linear, to achieve objectives and create meaningful connections between the past and the future."
                    textAlign="left"
                />
              </div>
              <div
                  className="value-item"
                  data-aos="zoom-out-up"
                  data-aos-offset="100"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
              >
                <ImageTextHome
                    text="HOW WE DO IT"
                    imageSrc="/LogoRibes_In_Out - Copy.gif"
                    description="We develop a wide range of high-tech solutions tailored to enhance the cultural heritage experience."
                    textAlign="right"
                    reverse={true}
                />
              </div>
            </div>
          </div>
        </div>



        <div id='main-features' className="h-20"></div>

        {/* OPEN POSITIONS Section */}
        <div className="px-10">
          <div
            className="flex items-center justify-center"
            data-aos="fade-zoom-in"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            {/*immagine product*/}
            <img 
              src="/projects/MARTINI RED CIRCLE/MARTINI RED CIRCLE.png"
              className="w-auto md:w-5/12  h-auto "
                 alt="sara"
            />
          </div>
          <div>
            {/*cta download*/}
            <div
              className="flex flex-col  sm:flex-row justify-center items-center gap-2 md:gap-24  font-bold  "
              data-aos="fade-zoom-in"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
            >

              <div className="flex justify-center  pb-20"> <BlackButtons to1="/clients"  description1="OUR CLIENTS" to2="/services/cultural-heritage/#gallery" description2="CASE HISTORY" onClick={()=>{}} /></div>


            </div>
          </div>

          <div className="py-20 " id="infos"></div>
          <h1 data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="0"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              className=" mb-12 text-5xl leading-120 tracking-tighter text-center uppercase  text-white " >
            WHAT WE CAN DO{" "}

          </h1>

          <div data-aos="fade-zoom-in"
               data-aos-offset="100px"

               data-aos-easing="ease-in-sine"
               data-aos-duration="600"
               data-aos-once="true">
            <ProductDescription
                title="Interactive Touchscreens"
                description="Interactive touchscreens allow visitors to explore exhibits at their own pace, providing detailed information, images, and videos that enhance the storytelling around artifacts. By offering a hands-on, immersive experience, these tools make cultural heritage more engaging and accessible to all types of audiences."
                icon="/icons/interactive_space.svg"/>

          </div>

          <div data-aos="fade-zoom-in"
               data-aos-offset="100px"
               data-aos-delay="500"
               data-aos-easing="ease-in-sine"
               data-aos-duration="600"
               data-aos-once="true">
            <ProductDescription
                title="ar & vr & XR experiences"
                description="Augmented Reality (AR), Virtual Reality (VR), and Extended Reality (XR) experiences transport visitors into new dimensions of cultural heritage. AR overlays digital information onto real-world environments, VR creates fully immersive virtual worlds, and XR combines elements of both. These experiences allow users to explore historical sites, view reconstructions, or interact with artifacts in ways that are otherwise impossible, preserving the past while using cutting-edge technology."
                icon="/icons/head_mounted_device.svg"/>

          </div>
          <div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title="Multimedia Kiosks"
                description="Multimedia kiosks are standalone stations that offer digital content through videos, images, and text. They can host interactive timelines, maps, or detailed information about exhibits. By offering an intuitive and user-friendly way to access in-depth information, multimedia kiosks deepen the visitor’s engagement with cultural heritage."
                icon="/icons/jamboard_kiosk.svg"
            />
          </div>
          <div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title="3D Scanning and Digital Reconstruction"
                description="Through 3D scanning and digital reconstruction, cultural heritage artifacts and sites are preserved in digital form. This technology allows visitors to explore high-resolution replicas of delicate or distant items, ensuring preservation while offering an in-depth, interactive look at cultural treasures. Digital models can also be used for restoration efforts or virtual tours."
                icon="/icons/deployed_code_account.svg"
            />
          </div>
          <div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title="Mobile Applications"
                description="Custom mobile apps serve as personal guides for visitors, offering interactive maps, audio tours, and multimedia storytelling. These apps provide a personalized experience, allowing users to explore collections in their own way while accessing rich content that enhances their understanding of cultural heritage."
            icon="/icons/phone_android.svg"
            />
          </div>
          <div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title="Projection Mapping"
                description="Projection mapping turns physical spaces—such as buildings or exhibitions—into dynamic storytelling surfaces. By projecting images and videos onto irregular surfaces, projection mapping creates engaging, immersive environments that bring historical narratives to life. This visually striking medium captivates visitors and adds an extra layer of meaning to exhibitions."
                icon="/icons/Group 1.svg"
            />
          </div> <div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title="Interactive Installations"
                description="Interactive installations, such as touch-based displays, motion-activated exhibits, or sensor-driven experiences, encourage active participation from visitors. These installations transform cultural spaces into interactive learning environments, making heritage accessible in new and creative ways, while fostering deeper engagement."
                icon="/icons/hand_bones.svg"
            />
          </div><div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title="Audioguides"
                description="Audioguides enhance the visitor experience by offering rich, narrated content about exhibits, tailored to different languages or levels of expertise. This technology allows cultural institutions to provide in-depth commentary and storytelling, guiding visitors through exhibits and deepening their connection to cultural heritage."
                icon="/icons/account_balance.svg"
            />
          </div><div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title="3d Modeling"
                description="3D modeling allows for the creation of detailed, digital replicas of objects, artifacts, and sites. These models can be used in virtual tours, educational programs, and digital archives, offering visitors the chance to examine items from all angles and perspectives, ensuring broader access to cultural heritage even when physical visits are not possible."
                icon="/icons/view_in_ar.svg"
            />
          </div>
          <div
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              data-aos-once="true">
            <ProductDescription
                title="Touchless systems"
                description="Touchless systems allow visitors to interact with exhibits without physically touching any surfaces, using gestures, voice commands, or sensors. These systems are particularly valuable in enhancing accessibility and hygiene in public spaces. By using motion sensors or voice recognition technology, visitors can navigate through information, activate multimedia content, or control interactive displays effortlessly."
                icon="/icons/multimodal_hand_eye.svg"
            />
          </div>


        </div>

        {/* COLLAPSIBLE Section */}
        {/*<div className="h-full flex flex-col bg-black bg-opacity-50 z-40 my-40 ">

        <h1 data-aos="fade-zoom-in"
            data-aos-offset="100px"
            data-aos-delay="0"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
            className=" my-12 text-5xl leading-120 tracking-tighter text-center uppercase font-bold text-white ">
          customize your sara{" "}
        </h1>
        <div className="flex items-center justify-center "
             data-aos="fade-zoom-in"
             data-aos-offset="100px"
             data-aos-delay="500"
             data-aos-easing="ease-in-sine"
             data-aos-duration="600">
          <img
              src="/SARA 2.0_ESPLOSO.png"
              className="w-auto md:w-3/12 h-auto"
              alt="sara"/>
        </div>
        <div>
          <div
              className="grid  md:grid-cols-3  gap-4 px-4 sm:grid-cols-1"
              data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="1000"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
          >
            <div className="flex items-start justify-start ">
              <Collapsible
                  title="CORE MODULE"
                  additionalText={[
                    { description: "drive mechanism (SADM)" },
                    { description: "drive electronics (SADE)" },

                  ]}

              />
            </div>
            <div className="flex items-start justify-start">
              <Collapsible
                  title="ADD-ONS"
                  additionalText={[
                    { description: "sun sensors" },
                    { description: "high-reliability sun tracking algorithm" },
                    { description: "SnapBack" },
                    { description: "deployment hinges" },
                    { description: "solar array yoke" },

                  ]}

              />
            </div>
            <div className="flex items-start justify-start">
              <Collapsible
                  title="CUSTOMIZATION OPTIONS"
                  additionalText={[
                    { description: "multiple mounting and deployment configurations available" },
                    { description: "custom connectors" },
                    { description: "dedicated testing" },

                  ]}

              />
            </div>
          </div>
        </div>
      </div>*/}

        {/* PRODUCT GALLERY Section */}
        <div className="h-full flex flex-col bg-black bg-opacity-50 z-40 mb-40 " id="gallery">
          {/*  */}
          <h1
              data-aos="fade-zoom-in"
              data-aos-offset="300px"
              data-aos-delay="0"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              className=" mt-20 mb-40  text-5xl leading-120 tracking-tighter text-center uppercase text-white ">
            PRODUCT GALLERY{" "}
          </h1>

          <div data-aos="fade-zoom-in"
               data-aos-offset="300px"
               data-aos-delay="300"
               data-aos-easing="ease-in-sine"
               data-aos-duration="600">
            <HeritageSlider />
          </div>

          <div className="object-fill mt-20 w-full  items-center flex flex-col" data-aos="fade-zoom-in"
              data-aos-offset="300px"
              data-aos-delay="300"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600">
            <iframe className="items-center justify-center" width="1500" height="560" src="https://www.youtube.com/embed/ka6IN3Wya3A?si=zucDZddz1kCmfJz0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>

          </div>
        </div>

        {/*ANIMATED BLOCKS*/}

        <div className="  h-full flex flex-col bg-black bg-opacity-50 z-0 my-20">

          <div className=" grid grid-cols-1 md:grid-cols-3 gap-4  sm:mt-0 text-white">
            <Counter type="power" start={1} end={26} unit="y" />
            <Counter type="letter" start="" end="TECH" />
            <Counter type="watt" start={100} end={1000} unit="" />
          </div>
        </div>

        {/*AVAILABLE VERSIONS Section*/}
        <div className="h-full flex flex-col bg-black bg-opacity-50 z-40 mb-40 ">
          {/* Your content here */}
          <h1 data-aos="fade-zoom-in"
              data-aos-offset="100px"
              data-aos-delay="0"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              className=" my-12 text-5xl leading-120 tracking-tighter text-center uppercase f text-white ">
            DO YOU HAVE AN IDEA?
          </h1>

          <div>
            <div className="grid  md:grid-cols-3  gap-4 px-4 sm:grid-cols-1">
              <div className="">
                {/*bg-green-500*/}
                <div className="value-item p-4  transform transition-transform duration-300 hover:scale-110 hover:text-white hover:bg-none hover:border-4 hover:border-white hover:shadow-[0_0_15px_rgba(255,165,0,0.7)] ease-in-out"
                     data-aos="fade-zoom-in"
                     data-aos-offset="100px"
                     data-aos-delay="500"
                     data-aos-easing="ease-in-sine"
                     data-aos-duration="600">
                  <ImageText
                    imageSrc="/icons/stylus_note (1).svg"
                    title="we can design that"
                    description={"We create custom designs, from exhibition layouts to digital interfaces,\n ensuring visually engaging and functional results.Each wing controlled independently"}
                    textAlign="center"
                  />
                </div>
              </div>

              <div className=" ">
                {/*bg-green-500*/}
                <div className="value-item p-4  transform transition-transform duration-200 hover:scale-110 hover:text-white hover:bg-none hover:border-4 hover:border-white hover:shadow-[0_0_15px_rgba(255,165,0,0.7)]"
                     data-aos="fade-zoom-in"
                     data-aos-offset="100px"
                     data-aos-delay="800"
                     data-aos-easing="ease-in-sine"
                     data-aos-duration="600">
                  <ImageText
                    imageSrc="/icons/desktop_windows (1).svg"
                    title="we can project hardware"
                    description={"We provide comprehensive hardware installation, integrating all\n components seamlessly for optimal performance and synchronization."}
                    textAlign="center"
                  />
                </div>
              </div>

              <div className="value-item  p-4  transform transition-transform duration-200 hover:scale-110 hover:text-white hover:bg-none hover:border-4 hover:border-white hover:shadow-[0_0_15px_rgba(255,165,0,0.7)]"
                   data-aos="fade-zoom-in"
                   data-aos-offset="100px"
                   data-aos-delay="1100"
                   data-aos-easing="ease-in-sine"
                   data-aos-duration="600" >
                <ImageText
                  imageSrc="/icons/sdk (1).svg"
                  title="we can develop software"
                  description={"We build advanced, custom software solutions that deliver flawless\n interactive experiences tailored to your project needs."}
                  textAlign="center"
                />
              </div>
            </div>
          </div>
        </div>
                  <div id="tech-specs" className="h-10"></div>
        {/*
        <div  className="h-full flex flex-col bg-black bg-opacity-50 z-40 mb-40">

          <h1
              data-aos="fade-zoom-in"
              data-aos-offset="300px"
              data-aos-delay="0"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
              className=" my-12 text-5xl leading-120 tracking-tighter text-center uppercase font-bold text-white ">
            TECHNICAL SPECIFICATIONS{" "}
          </h1>

          <div>
            <div className="grid  md:grid-cols-4  gap-4 px-4 sm:grid-cols-1 " >

              <div
                  data-aos="fade-zoom-in"
                  data-aos-offset="300px"
                  data-aos-delay="400"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600">
                <TechSpecs
                    title="MECHANICAL SPECS"
                    description={[
                      { title: "Size: ", description: "97x97x23mm" },
                      { title: "Mass: ", description: "0.3kg" },
                      { title: "Rotation range: ", description: " +/- 180°" },
                      {
                        title: "Nominal rotation speed: ",
                        description: " 10 deg/s",
                      },
                    ]}

                />
              </div>
              <div
                  data-aos="fade-zoom-in"
                  data-aos-offset="300px"
                  data-aos-delay="700"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
              >
                <TechSpecs
                    title="ELECTRICAL SPECS"
                    description={[
                      { title: "Power supply:  ", description: "12V (unregulated 10-18V)" },
                      { title: "power consumption:  ", description: "Idle: 0.2W\nHOLD: 2W\nACTUATION: 5W" },
                      { title: "power transfer type: ", description: "flex pcb (twist capsule)" },
                      {
                        title: "Maximum power transfer: ",
                        description: "500W @42V",
                      },
                      {
                        title: "Communication interface:  ",
                        description: "RS485 / CAN",
                      },
                    ]}

                />
              </div>
              <div
                  data-aos="fade-zoom-in"
                  data-aos-offset="300px"
                  data-aos-delay="1000"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
              >
                <TechSpecs
                    title="THERMAL SPECS"
                    description={[
                      { title: "Operating  temperature range: ", description: "-40/+60 °C" },
                      { title: "SURVIVAL temperature range: ", description: "-50/+70 °C" },

                    ]}

                />
              </div>
              <div
                  data-aos="fade-zoom-in"
                  data-aos-offset="300px"
                  data-aos-delay="1300"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
              >
                <TechSpecs
                    title="MODES OF OPERATIONS"
                    description={[
                      { title: "Autonomous  ", description: "sun tracking based on sara’s sun sensor data" },
                      { title: "Freeze ", description: "pauses autonomous actuation for a given time" },
                      { title: "Slave  ", description: " target angle set by the on-board computer" },
                      {
                        title: "Safe ",
                        description: " panels are (mechanically) returned to the reference angle by snapback",
                      },
                    ]}

                />
              </div>
            </div>
          </div>
        </div>*/}


        {/* VIDEO Section */}
        {/*<div className="opacity-0 animate-fadeIn delay-0 h-full flex flex-col bg-black bg-opacity-50 z-40">


        <div>
          <div>

            <video
                className="inset-0 w-screen h-full object-cover"
                loop={true}
                autoPlay={false}
                controls
            >
              <source src="/SARA_2.0_DIDASCALIE.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>*/}

        {/*FORM SECTION*/}
        <div className="max-w-md mx-auto mt-0 px-6 "  data-aos="fade-zoom-in"
             data-aos-offset="300px"
             data-aos-delay="0"
             data-aos-easing="ease-in-sine"
             data-aos-duration="600">
          <h1
              id='positions'
              className="opacity-0 animate-fadeIn delay-2 my-12 text-5xl leading-120 tracking-tighter text-center uppercase font-medium text-white ">
            LET'S TALK ABOUT IT!
          </h1>
          <ProductWebForm
              formId="95a0e1ff-e505-4b30-9253-a3f16bc466a6"
              portalId="145047450"
              region="eu1"
          />
        </div>



        <div className="mt-40  z-40 bg-black">
          <Link to="/contact">
            <div className="flex items-center justify-center ">
              <button
                  className=" w-auto h-10 text-center text-white  py-2 px-4 transform transition-transform duration-200 hover:scale-110"
                  style={{
                    background: "linear-gradient(90deg, #000000 0%, #eb6000 100%)",
                  }}
              >
                CONTACT US
              </button>
            </div>
          </Link>
        </div>

      </div>
      {/* {isPopupOpen && fileType && (
          <EmailPopup fileType={fileType} onClose={handleClosePopup} />
      )}*/}
      {/*{isPopupOpen && (
          <OverlayForm
              formUrl="https://webforms.pipedrive.com/f/6UR9Re8nR5VKrsxbJHNTBDLcsXv6xjIStMZYDU4iiOvEJdEo7cZwg4M1uAm9NAG7WX"
              onClose={handleClosePopup}
          />
      )}*/}

    </div>
  );
}
